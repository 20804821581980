import styled from 'styled-components'

export const Container = styled.div`
    padding: 60px 60px;
    background: linear-gradient(-45deg, #6ca4b5, white, white, #6ca4b5);
`
export const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1250px;
        margin: 0 auto;
`

export const Row = styled.div`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 10px;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        }
`

export const Column = styled.div`
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;
`

export const Link = styled.a`
        color: #6ca4b5;
        margin-bottom: 20px;
        font-size: 18px;
        text-decoration: none;
        &:hover {
            color: #ff9c00;
            transition: 200ms ease-in;
        }
`

export const Copy = styled.span`
        color: #6ca4b5;
        margin-bottom: 20px;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
        &:hover {
            color: #ff9c00;
            transition: 200ms ease-in;
        }
`

export const Title = styled.p`
        font-size: 24px;
        color: #6ca4b5;
        margin-bottom: 40px;
        font-weight: bold;
`

