
import './App.css';
import React, { useEffect, useState }  from 'react';
//import * as localForage from 'localforage';
//import { createTheme } from '@mui/material/styles';
// import { makeStyles } from "@material-ui/core";

//import * as generalService from '../services/generalService';
import Header from '../components/Header';

//import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import CookieBanner from '../components/CookieBanner';
import posthog from 'posthog-js';

// const theme = createTheme({
//   palette: {
//     primary:{
//       main: "#333996",
//       light: "#3C44B126"
//     },
//     secondary: {
//       main: "#F83245",
//       light: "#F8324526"
//     },
//     default: {
//       main: "#9E9E9E",
//       light: "#0E0E0E"
//     },
//     background: {
//       default: "#F4F5FD"
//     }
//   },
//   overrides: {
//     MuiAppBar: {
//       root: {
//         transform: 'translateZ(0)',
//       }
//     }
//   },
//   props: {
//     MuiIconButton: {
//       disableRipple: true,
//     }
//   }
// });


// const useStyles = makeStyles(theme => ({
//   appMain: {
//     // paddingLeft: '250px',
//     // width: '100%'
//   },
//   root: {
//     backgroundColor: '#fafafa',
//   },
//   btn: {
//     padding: theme.spacing(1)
//   },
//   menu: {
//     display: 'flex',
//     top: '60px',
//     position: 'fixed',
//     width: '80%',
//     zIndex: 2,
//     backgroundColor: '#fafafa',
//   },
//   splash: {
//     width:  '60%',
//     height: 'auto',
//     position: 'absolute',
//     top: '10rem',
//     left: '35%',
//     opacity: '0.2',
//     '@media(max-width: 700px)': {
//       width: '90%',
//       left: '5%',
//     }
//   },
//   headline: {
//     color: '#5f7383',
//     fontSize: '30px',
//     position: 'absolute',
//     left: '6%',
//     top: '10rem',
//     '@media(max-width: 700px)': {
//       // fontSize: '30px',
//       // top: '8rem',
//       // left: '3%',
//       color: '#fafafa',
//     }
//   },
//   headline1: {
//     color: '#5f7383',
//     fontSize: '30px',
//     position: 'absolute',
//     left: '6%',
//     top: '14rem',
//     '@media(max-width: 700px)': {
//       // fontSize: '30px',
//       // top: '8rem',
//       // left: '3%',
//       color: '#fafafa',
//     }
//   }
// }))


// *********************************** App ************************************ //


function App(props) {

  //const { t } = useTranslation();
  //const classes = useStyles();
// eslint-disable-next-line
  const [ lng, setLng ] = useState('pt-BR')

  useEffect(() => {
    if (!sessionStorage.getItem('lng')) {
    sessionStorage.setItem('lng', 'pt-BR');
    setLng('pt-BR')
    }


  }, [])

  const changeLanguage = (lng) => {
    sessionStorage.setItem('lng', lng);
    setLng(lng);
    i18n.changeLanguage(lng);
    window.location.reload(true);
  };

  return (
    <React.Fragment>
      <Header
        changeLanguage={changeLanguage}
      />
      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing() ? null : <CookieBanner />}
      {/* <CookieBanner /> */}
    </React.Fragment>
  )
}

export default App;
