import React, { useState, useEffect } from 'react'
import { HashRouter, Link, Route, Routes } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
//import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from "@material-ui/core";
// import Grid from '@mui/material/Grid';
import { IconButton, Badge } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
//import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
//import MuiAlert from '@mui/material/Alert';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { blue, red } from '@mui/material/colors';

import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FactoryIcon from '@mui/icons-material/Factory';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import Avatar from '@mui/material/Avatar';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from '@mui/material/CircularProgress';
import {
    IconFlagBR,
    IconFlagUS
} from 'material-ui-flags';

import i18n from 'i18next';
import k from "./../i18n/keys";

import * as yup from "yup"
import axios from 'axios';

import About from '../components/About';
import Home from '../components/Home';
import Oem from '../components/Oem';
import Fleet from '../components/Fleet';
import Rental from '../components/Rental';
import Solutions from '../components/Solutions';
import System from '../components/System';
import ContactUs from '../components/ContactUs';
import Mission from '../components/Mission';
import Vision from '../components/Vision';
import Webhook from '../components/Webhook';

import ModalLogin from '../components/Modals/LoginModal';
import ModalForgot from '../components/Modals/LoginForgot';
import ModalMessage from '../components/Modals/ModalMessage';

// import { FooterContainer } from '../containers/footer';

//import * as generalService from '../services/generalService';
import logo from './../assets/images/trackiq.png';
//import { Schema } from '@mui/icons-material';


// withStyles & makeStyles


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff !important',
        transform: 'translateZ(0)',
        //position: 'static',

    },
    searchInput: {
        opacity: '0.6',
        padding: '0px 8px',
        fontSize: '0.8rem',
        marginLeft: '1.5rem',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#f2f2f2'
        },
        '& .MuiSvgIcon-root': { // If there is an icon (copied from the search icon), please apply margin 8px
            marginRight: theme.spacing(1)   // spacing(1) = '8px'
        }
    },
    logo: {
        display: 'block',
        position: 'relative',
        width: '100%',
        maxWidth: '10rem',
        margin: '5px auto 0 auto',
    },
    logo_a: {
        width: 'auto',
    },
    drawer: {
        width: 150,
        background: '#fafafa',

        '@media(max-width: 700px)': {
            width: 100,
        }
    },
    drawerItem: {
        // fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif!important',
        fontSize: '0.8rem !important',
        fontWeight: 600,
        color: '#c3a67c',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
    }
}))


export default function Header(props) {

    const { changeLanguage } = props;

    const classes = useStyles();

    const baseURL = "https://trackiq.onrender.com";

    //const menuColors = generalService.getMenuIcons();

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    // eslint-disable-next-line
    const [state, setState] = useState({
        openSnack: false,
        vertical: 'top',
        horizontal: 'center'
    })
    // eslint-disable-next-line
    const [msg, setMsg] = useState( i18n.t(k.ERROR_LOGIN) );

    const [ showLoginForm, setShowLoginForm ] = useState(false);
    // const [ showRegisterForm, setShowRegisterForm ] = useState(false);
    const [ showLogoutForm, setShowLogoutForm ] = useState(false);
    const [ showLoginForgot, setShowLoginForgot ] = useState(false);
    // eslint-disable-next-line
    const [ logged, setLogged ] = useState(false);
    const [ changeState, setChangeState ] = useState(false);
    // eslint-disable-next-line
    const [ open, setOpen ] = useState(false);
    // eslint-disable-next-line
    const [ token, setToken ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmation, setConfirmation ] = useState('');
    // eslint-disable-next-line
    const [ login, setLogin ] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorConf, setErrorConf] = useState(false);
    const [ display, setDisplay ] = useState('none');
    // eslint-disable-next-line
    const [ data, setData ] = useState([]);
    // eslint-disable-next-line
    const [username, setUsername] = useState('');
    // eslint-disable-next-line
    const [ firstname, setFirstname ] = useState('');
    // eslint-disable-next-line
    const [ lastname, setLastname ] = useState('');
    const [ userAvatar, setUserAvatar ] = useState('');
    // eslint-disable-next-line
    const [ notifications, setNotifications ] = useState(3);

    // eslint-disable-next-line
    const { openSnack, vertical, horizontal } = state

    //const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);


    // const Alert = React.forwardRef(function Alert(props, ref) {
    //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    // })

    //const services = ['Registrar', 'Configurações', 'Sair'];
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    useEffect(() => {
        if (username !== '' && firstname !== '' && lastname !== '') {
            setUserAvatar(firstname.charAt(0) + lastname.split(' ').pop().charAt(0));
        } else {
            setUserAvatar(<AccountCircle/>);
        }
        // eslint-disable-next-line
    }, [logged, username, firstname, lastname]);

    useEffect(() => {
        if (localStorage.getItem('notifications')) {
            setNotifications(localStorage.getItem('notifications'));
        }
    // eslint-disable-next-line
    }, [localStorage.getItem('notifications')]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };


    const handleMenuClose = (name, event) => {
        console.log('MenuClose', name);
        if ( name === 'login') {
        setShowLoginForm(true);
        setOpen(true);
        } else if ( name === 'logout' && logged) {
        setShowLogoutForm(true);
        setShowLoginForgot(false);
        setShowLoginForm(false);
        setOpen(true)
        }
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleClose = (event, name) => {
        console.log('handleClose', name);
        if (name === 'login') {
            setShowLoginForm(false);
            setOpen(false);
        }
        // if (name === 'register') {
        //     setShowRegisterForm(false);
        // }
        if (name === 'logout') {
            setShowLogoutForm(false);

        }
        if (name === 'sair') {
            setShowLogoutForm(false);
            setLogged(false);
            clearAtLogout();

        }
        if (name === 'forgot') {
            setShowLoginForgot(false);
            setLogged(false);
            clearAtLogout();
            return;
        }

        setErrorLogin(false);
        setChangeState(!changeState);
        setOpen(false);
    }

    const clearAtLogout = () => {
        setShowLoginForm(false);
        // setUserInitial('');
        setEmail('');
        setPassword('');
        setFirstname('');
        setLastname('');
        setLogged(false);
        setChangeState(!changeState)
    }

    const instance = axios.create({
        baseURL: "https://trackiq.onrender.com",
    });

    instance.interceptors.response.use(
        (response) => response,
        (error => {
            if (error.response && error.response.status === 401) {
                console.log('Unauthorized request!');
            }
            return Promise.reject(error);
        })
    );

    const handleSubmit = async (event) => {
        //console.log('Submit Login', event.target.innerText)
        event.preventDefault();
        let schema = yup.object().shape({
            email: yup.string().email().required(),
            password: yup.string().required(),
        });

        const pass_valid = password.length >= 5;

        const endpoint = '/sessions/login'
        const data = {
            email: email,
            password: password,
        }
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        //console.log('Email e Password', email, password, await schema.isValid(data))
        await schema.isValid(data).then((valid) => {
            if (valid && pass_valid === true) {
                //console.log('Validado', valid, pass_valid)
                setErrorEmail(false);
                setErrorPass(false);
                setDisplay('flex');
                instance.post(baseURL + endpoint, data, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log('Response', response.data)
                            //setConnected(true);
                            setLogged(true);
                            setErrorLogin(false);
                            setUsername(response.data.username);
                            setFirstname(response.data.firstname);
                            setLastname(response.data.lastname);
                            setData(response.data);
                            setToken(response.data.token);
                            setDisplay('none')
                            handleClose('', 'login');
                        } else if (response.status === 401) {
                            console.log('Response Not Authorized - ', response.status)
                            setErrorLogin(true);
                            setDisplay('none')
                            console.log('Axios errors')
                            return false
                        } else {
                            console.log('Response 400 - ', response.status);
                            return false
                        }
                        setDisplay('none');
                    })
                    .catch((error) => {
                        setErrorLogin(true);
                        setDisplay('none');
                        console.error('Error', error.message)
                        //setOpen(true);
                    });
            } else {
                setErrorLogin(true);
                setErrorEmail(true);
                setErrorPass(true);
                console.log('Erro de validacao', email, password)
            }

        })
    };


    const handleSubmitForgot = async (event) => {
        //console.log('Submit Login', event.target.innerText)
        event.preventDefault();
        let schema = yup.object().shape({
            email: yup.string().email().required(),
            password: yup.string().required(),
        });

        const pass_valid = password.length >= 5 && password === confirmation;

        const endpoint = '/sessions_change'
        const data = {
            email: email,
            password: password,
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': "Bearer " + token,
            }
        };
        console.log('Email e Password', email, password, pass_valid, await schema.isValid(data))
        await schema.isValid(data).then((valid) => {
            if (valid && pass_valid === true) {
                console.log('Validado', valid, pass_valid)
                setErrorEmail(false);
                setErrorPass(false);
                setErrorConf(false);
                setDisplay('flex');
                instance.put(baseURL + endpoint, data, config)
                    .then((response) => {
                        if (response.status === 200) {
                            console.log('Response', response.data)
                            //setConnected(true);
                            setLogged(true);
                            setErrorLogin(false);
                            setUsername(response.data.username);
                            setFirstname(response.data.firstname);
                            setLastname(response.data.lastname);
                            setData(response.data);
                            setToken(response.data.token);
                            setDisplay('none')
                            handleClose('', 'login');
                        } else if (response.status === 401) {
                            console.log('Response Not Authorized - ', response.status)
                            setErrorLogin(true);
                            setDisplay('none')
                            console.log('Axios errors')
                            return false
                        } else {
                            console.log('Response 400 - ', response.status);
                            return false
                        }
                        setDisplay('none');
                    })
                    .catch((error) => {
                        setErrorLogin(true);
                        setDisplay('none');
                        console.error('Error', error.message)
                        //setOpen(true);
                    });
            } else {
                setErrorLogin(true);
                setErrorEmail(true);
                setErrorPass(true);
                setErrorConf(true);
                console.log('Erro de validacao', email, password)
            }

        })
    };

    const handleInputChange = (event) => {
        //console.log('Input Change', event.target.name, event.target.value);
        const { name, value } = event.target;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmation':
                setConfirmation(value);
                break;
            case 'username':
                setUsername(value);
                break;

            default:
                console.log('Error switch')
        }
    }

    const handleForgot = (event) => {
        console.log('Forgot', event);
        setOpen(false)
        setShowLoginForm(false)
        setShowLoginForgot(true);
        setOpen(true);
    }





    // function handleMenuChange(item) {
    //    console.log('Menu Solutions')
    // }


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={(event) => handleMenuClose('login', event)} name="login">{i18n.t(k.LOG)}</MenuItem>
            {/* <MenuItem onClick={(event) => handleMenuClose('register', event)} name="register">Registro</MenuItem> */}
            <MenuItem onClick={(event) => handleMenuClose('logout', event)} name="logour">{i18n.t(k.EXIT)}</MenuItem>
        </Menu>
    );


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/* <AccountCircle sx={{ color: '#6ca4b5', ml: 0 }} /> */}
                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex', justifyContent: "flex-start" } }}>
                        <Avatar sx={{ my: 1 }} aria-label="user">
                            {userAvatar}
                        </Avatar>
                    </Box>
                </IconButton>
                <p>{i18n.t(k.ACCOUNT)}</p>
            </MenuItem>

            <MenuItem onClick={() => changeLanguage('en')}>
            <IconButton
                size="small"
                edge="end"
                aria-label="US Flag"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
            >
                <IconFlagUS />
            </IconButton>
            </MenuItem>

            <MenuItem onClick={() => changeLanguage('pt-BR')}>
            <IconButton
                size="small"
                edge="end"
                aria-label="BR Flag"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
            >
                <IconFlagBR />
            </IconButton>
            </MenuItem>

            <MenuItem>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                <Link to="/about" className={classes.link}>
                <IconButton
                    size="small"
                    edge="end"
                    aria-label="About us"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                >
                <InfoIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                </IconButton>
                <Typography
                    variant="body2"
                    noWrap
                    component="div"
                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                >
                    {i18n.t(k.ABOUT)}
                </Typography>
                </Link>
            </Box>
            </MenuItem>

            <MenuItem >

                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/oem" className={classes.link}>
                    <IconButton
                        size="small"
                        edge="end"
                        aria-label="Car OEM"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                    <FactoryIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                    </IconButton>
                    <Typography
                        variant="body2"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                    >
                        {i18n.t(k.OEM)}
                    </Typography>
                </Link>
                </Box>

            </MenuItem>

            <MenuItem >
                <Link to="/fleet" className={classes.link}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                <IconButton
                    size="small"
                    edge="end"
                    aria-label="Fleet"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <DirectionsCarFilledIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                </IconButton>
                <Typography
                    variant="body2"
                    noWrap
                    component="div"
                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                >
                    {i18n.t(k.FLEET)}
                </Typography>
                </Box>
                </Link>
            </MenuItem>

            <MenuItem >
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/rental" className={classes.link}>
                    <IconButton
                        size="small"
                        edge="end"
                        aria-label="Car Rental"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                    <CarRentalIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                    </IconButton>
                    <Typography
                        variant="body2"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                    >
                        {i18n.t(k.RENTAL)}
                    </Typography>
                    </Link>
                </Box>
            </MenuItem>

            <MenuItem >
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/solutions" className={classes.link}>
                    <IconButton
                        size="small"
                        edge="end"
                        aria-label="Field Service"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                    <NoCrashIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                    </IconButton>
                    <Typography
                        variant="body2"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                    >
                        {i18n.t(k.SOLUTIONS)}
                    </Typography>
                    </Link>
                </Box>
            </MenuItem>

            <MenuItem>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/contact" className={classes.link}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="Contact us"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <ContactsIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                        </IconButton>
                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                        >
                            {i18n.t(k.CONTACT)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>

            <MenuItem >
                {logged &&
                    <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                        <Link to="/system" className={classes.link}>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="Field Service"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            >
                            <NoCrashIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                            </IconButton>
                            <Typography
                                variant="body2"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                            >
                                {i18n.t(k.SYSTEM)}
                            </Typography>
                        </Link>
                    </Box>}
            </MenuItem>



        </Menu>
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    return (
        <HashRouter>
        <>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className={ classes.root}>
                <Toolbar sx={{ background: grey[200] }} >
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => setIsDrawerOpen(true)}
                    >
                    <MenuIcon sx={{ color: grey[500]}} />
                    </IconButton>

                    <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} className={classes.height}>
                        <List className={classes.drawer}>
                            <ListItem button component={Link} to="/">
                                        <ListItemText primary={i18n.t(k.HOME)} className={classes.drawerItem}/>
                            </ListItem>

                            <ListItem button component={Link} to="/about">
                                        <ListItemText primary={i18n.t(k.ABOUT)} />
                            </ListItem>

                            <ListItem button component={Link} to="/contact">
                                        <ListItemText primary={i18n.t(k.CONTACT_US)} />
                            </ListItem>

                            <ListItem button component={Link} to="/solutions">
                                        <ListItemText primary={i18n.t(k.SERVICES)} />
                            </ListItem>
                        </List>
                    </Drawer>

                    <Link to="/">
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'block', sm: 'block' }, mr: '3rem' }}
                    >
                    <img className={classes.logo} src={logo} alt="TrackIQ Logo" />
                    </Typography>
                    </Link>


                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 'auto'}} className="navbar-grid">
                        <MenuItem>
                            <Box  sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
                            <Link to="/about" className={classes.link}>
                            <IconButton

                                size="large"
                                edge="end"
                                aria-label="About us"
                                aria-controls={menuId}
                                aria-haspopup="true"

                                color="inherit"
                            >
                            <InfoIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5', ml: 0, textDecoration: 'none' }}
                            >
                                {i18n.t(k.ABOUT)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex'} , alignItems: 'center' }}>
                            <Link to="/oem" className={classes.link}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="OEM"
                                aria-controls={menuId}
                                aria-haspopup="true"

                                color="inherit"
                            >
                            <FactoryIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                            >
                            {i18n.t(k.OEM)}
                            </Typography>

                            </Link>

                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            <Link to="/fleet" className={classes.link}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="Fleet"
                                aria-controls={menuId}
                                aria-haspopup="true"

                                color="inherit"
                            >
                            <DirectionsCarFilledIcon sx={{ color: '#6ca4b5' }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5'}}
                            >
                                {i18n.t(k.FLEET)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            <Link to="/rental" className={classes.link}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="Car Rental"
                                aria-controls={menuId}
                                aria-haspopup="true"

                                color="inherit"
                            >
                                <CarRentalIcon sx={{ color: '#6ca4b5' }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5' } }
                            >
                                {i18n.t(k.RENTAL)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            <Link to="/solutions" className={classes.link}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="Services"
                                aria-controls={menuId}
                                aria-haspopup="true"

                                color="inherit"
                            >
                                <NoCrashIcon sx={{ color: '#6ca4b5'}} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5' }}
                            >
                                {i18n.t(k.SOLUTIONS)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <   Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                                <Link to="/contact" className={classes.link}>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        aria-label="Contact us"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <ContactsIcon sx={{ color: '#6ca4b5', ml: 0 }} />
                                    </IconButton>
                                    <Typography
                                        className="menu-hover"
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6ca4b5', ml: 0 }}
                                    >
                                        {i18n.t(k.CONTACT)}
                                    </Typography>
                                </Link>
                                </Box>
                        </MenuItem>


                        {logged &&
                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            <Link to="/system" className={classes.link}>

                                <Typography
                                    className="menu-hover"
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'none', sm: 'block' }, color: '#6ca4b5'}}
                                >
                                    {i18n.t(k.SYSTEM)}
                                </Typography>
                            </Link>
                            </Box>
                        </MenuItem>
                        }

                    </Box>


                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                        {/* <AccountCircle sx={{ color: '#6ca4b5' }} /> */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: "flex-start" } }}>
                                    <Avatar sx={{ my: 1, background: '#667888', width: 30, height: 30 }} aria-label="user">
                                {userAvatar}
                            </Avatar>
                        </Box>
                        </IconButton>

                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="US Flag"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => changeLanguage('en')}
                            color="inherit"
                        >
                        <IconFlagUS  />
                        </IconButton>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="BR Flag"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => changeLanguage('pt-BR')}
                            color="inherit"
                        >
                            <IconFlagBR />
                        </IconButton>
                    </Box>
                    <Box>
                     <IconButton>
                            {/* <Tooltip title= 'Novas notificações' arrow> */}
                            <Badge badgeContent = {notifications} color="secondary">
                                <NotificationsNoneIcon fontSize='small'/>
                            </Badge>
                            {/* </Tooltip> */}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon sx={{ color: grey[500] }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:lng" element={<Home changeLanguage={changeLanguage}/>} />
            <Route path="about" element={<About />} />
            <Route path="oem" element={<Oem />} />
            <Route path="fleet" element={<Fleet />} />
            <Route path="rental" element={<Rental />} />
            <Route path="solutions" element={<Solutions />} />
            <Route path="solution/:id" element={<Solutions />} />
            <Route path="system" element={<System />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="mission" element={<Mission />} />
            <Route path="vision" element={<Vision />} />
            <Route path="webhook/:id" element={<Webhook />} />
        </Routes>
        </>
        {showLoginForm ?
            <ModalLogin
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                email={email}
                password={password}
                errorEmail={errorEmail}
                errorPass={errorPass}
                errorLogin={errorLogin}
                // tab={tab}
                display={display}
                handleForgot={handleForgot}
            />
            :
            null
        }

        {showLogoutForm ?
            <ModalMessage
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                // tab={tab}
            />
            :
            null
        }

        {showLoginForgot && !showLoginForm?
            <ModalForgot
                open={open}
                password={password}
                confirmation={confirmation}
                errorConf={ errorConf}
                setOpen={setOpen}
                handleClose={handleClose}
                handleSubmitForgot={handleSubmitForgot}
                handleInputChange={handleInputChange}
                display={display}
                setDisplay={setDisplay}

                // tab={tab}
            />
            :
            null
        }
            <Box sx={{
                display: { display }, position: 'absolute', top: '50%', left: '50%'
            }}>
                <CircularProgress sx={{ color: '#51708e', width: '60px!important', height: '60px!important', zIndex: '5' }} />
            </Box>
            <Snackbar sx={{ mt: 5, width: 600 }}
                open={errorLogin}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert onClose={(event) => handleClose(event, 'forgot')} severity="error" sx={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: red[100], color: blue[900] }}>
                    {msg}
                </Alert>
            </Snackbar>
        </HashRouter>
    );
}
