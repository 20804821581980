import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function Webhook() {
    const params = useParams();
    useEffect(() => {
        if (params && params.id !== '') {
            console.log('Notification', params);
            localStorage.setItem('notifications', params.id);
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div>Webhook</div>
    )
}
