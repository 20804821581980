import React from 'react';


import Footer from '../components/footer';
import i18n from 'i18next';
import k from "./../i18n/keys";

import Icon from '../components/Icons';

import samplePDF from "./../components/cookies.pdf";


export function FooterContainer() {

  return (

    <Footer>
        <Footer.Wrapper>
            <Footer.Row>
                <Footer.Column>
                    <Footer.Title>{i18n.t(k.ABOUTUS)}</Footer.Title>
                    <Footer.Link href="#/about">{i18n.t(k.STORY)}</Footer.Link>
                    <Footer.Link href="#/vision">{i18n.t(k.VISION)}</Footer.Link>
                    <Footer.Link href="#/mission">{i18n.t(k.MISSION)}</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                    <Footer.Title>{i18n.t(k.SERVICES)}</Footer.Title>
                    <Footer.Link href="#/solution/professional">{i18n.t(k.PROFESSIONAL)}</Footer.Link>
                    <Footer.Link href="#/solution/consulting">{i18n.t(k.CONSULTING)}</Footer.Link>
                    <Footer.Link href="#/solution/intelligence">{i18n.t(k.INTELLIGENCE)}</Footer.Link>
                    <Footer.Link href="#/fleet">{i18n.t(k.CORPORATE_FLEET)}</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                    <Footer.Title>{i18n.t(k.CONTACT)}</Footer.Title>
                    <Footer.Link href="#/contact">{i18n.t(k.CONTACT_US)}</Footer.Link>
                      <Footer.Link href="#"><small>{i18n.t(k.EMAIL)}</small></Footer.Link>
                      <Footer.Link href="#"><small>{i18n.t(k.PHONE)}</small></Footer.Link>
                      <Footer.Link href="#"><small>{i18n.t(k.WHATS)}</small></Footer.Link>
                </Footer.Column>
                <Footer.Column>
                    <Footer.Title>{i18n.t(k.POLICIES)}</Footer.Title>
                    <Footer.Link href="#">{i18n.t(k.PRIVACY_POLICY)}</Footer.Link>
                    <Footer.Link href={samplePDF} target="_blank" rel="noopener noreferer">{i18n.t(k.COOKIES_POLICY)}</Footer.Link>
                    <Footer.Link href="#">{i18n.t(k.TERMS_OF_SERVICE)}</Footer.Link>
                    <Footer.Link href="#/contact">{i18n.t(k.WORK)}</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                      <Footer.Title>{i18n.t(k.FOLLOW_US)}</Footer.Title>
                    <Footer.Link href="#"><Icon className="fab fa-facebook-f" />Facebook</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-instagram" />Instagram</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-youtube" />Youtube</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-twitter" />X-Twitter</Footer.Link>
                    <Footer.Link href="#"><Icon className="fab fa-whatsapp" />Whatsapp</Footer.Link>
                </Footer.Column>

            </Footer.Row>
              <Footer.Copy >{i18n.t(k.COPYRIGHT)}</Footer.Copy>


        </Footer.Wrapper>

    </Footer>
  )
}
