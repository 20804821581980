import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App/App';
import './i18n/init';
import posthog from 'posthog-js'

posthog.init('phc_mmoKbzYXM1sk19UH11BPqRJFd4mP0Id2oAWeMDug49', { api_host: 'https://app.posthog.com' })



// ReactDOM.render(
//       <App />,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
