import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - Artificial Intelligence

// ============ React Dependencies ========

import React from 'react';

// ============ Image Imports =============
//
//import corporate from '../assets/images/corporate_image_gif_1.gif';
import corporate from '../assets/images/corporate3.jpg';
import corporate1 from '../assets/images/corporate_image_1.jpeg';
import corporate2 from '../assets/images/corporate_fleet1.jpg';
import corporate3 from '../assets/images/corporate_fleet2.jpg';
import corporate4 from '../assets/images/trackiq.png';
//import pconnect from '../assets/Pconnect_logo.svg';
import pconnect2 from '../assets/images/trackiq_connect.jpg';

import focus from '../assets/images/focus.svg';


// ============ About Component ==========

export default function Corporate() {
  return (
            <React.Fragment>
                <section id="corporate">
                    <div className="container-wrap">
                        <br /><br /><br />
                        <div className="about">
                            <div className="topic-header">
                                <p className="what-subheading">{i18n.t(k.CORPORATE)}</p>
                                <img src={corporate} style={{ width: '50%' }} alt="Corporate Fleet" />
                                <p className="what-subheading">{i18n.t(k.CORPORATE_TITLE)}</p>
                            </div>
                            <hr className="about-red-line"></hr>

                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE_HEAD)}
                            </strong>
                            </p>
                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE1)}
                            </strong>
                            </p>
                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE2)}
                            </strong>
                            </p>
                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE3)}
                            </strong>
                            </p>
                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE4)}
                            </strong>
                            </p>
                            <p className="text-center lorem"><strong>
                                {i18n.t(k.CORPORATE5)}
                            </strong>
                            </p>
                            <img src={pconnect2} className="corporate-pconnect" style={{ width: '8%' }} alt="Corporate Fleet" />
                            <div className="grid-what-container">
                                <div className="grid-what-item1">
                                    <div>
                                        <img className="img-fluid card corporate-tablets-image" src={corporate4} alt="iPad" />
                                        <img className="img-fluid card corporate-tablets-image" src={corporate1} alt="iPad" />
                                        <img className="img-fluid card corporate-tablets-image" src={corporate2} alt="iPad" />
                                        {/* <img className="img-fluid card corporate-tablets-image" src={corporate3} alt="iPad" /> */}
                                    </div>
                                    <div className="card corporate-tablets ">
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE6)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE7)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE8)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE9)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE10)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE11)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE12)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE13)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE14)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE15)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE16)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE17)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE18_1)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE18_2)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE18_3)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE18_4)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE19)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE20)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE21)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE22)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE23)}</p>
                                        <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.CORPORATE24)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </section>
            </React.Fragment>);

    }
