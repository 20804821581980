import i18n from 'i18next';
import k from "../i18n/keys";
// import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
// import { Button } from '@mui/material';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Typography from "@material-ui/core/Typography";
import Carousel from 'react-material-ui-carousel'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// ============ Image Imports =============

import cloud from './../assets/images/cloud_system.jpg';
import cloud_pt from './../assets/images/cloud_system_pt.jpg';
import corporate from '../assets/images/corporate_image_gif_1.gif'
import rental from './../assets/images/The-future-of-car-rental.jpg';
import solutions from './../assets/images/solutions1.jpg';
import img2 from './../assets/images/oem1.jpg';
import img1 from './../assets/images/what_image_2.jpg';
import img3 from './../assets/images/oem_V2V.jpg';
import img4 from './../assets/images/oem_V2V1.png';
import img5 from './../assets/images/oem3.jpg';
import img6 from './../assets/images/customizations.jpg';
import img7 from './../assets/images/ai2.jpg';
import img8 from './../assets/images/electric_image_1.jpg';

//import Telematics from './../components/Telematics';

import { FooterContainer } from '../containers/footer';
// ============ OEM Component ==========


const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: '#5f7383',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    cardItem: {
        background: 'linear-gradient(-45deg, #6ca4b5, white, white, #6ca4b5)',
        textAlign: 'center',
        color: '#6ca4b5',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#5f7383',
        margin: '5px',
        fontSize: '1rem',
    },
    cardButton: {
        color: '#6ca4b5',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
    }
}))


export default function Home(props) {

    //const { t } = useTranslation();

    const classes = useStyles();

    const params = useParams();
    useEffect(() => {
        if (params && params.lng && sessionStorage.getItem('lng') !== params.lng) {
            sessionStorage.setItem('lng', params.lng);
            props.changeLanguage(params.lng);
        }
    // eslint-disable-next-line
    }, [])

    function Item(props) {
        return (
            <Paper elevation={0} className="carousel-home-title" sx={{ color: '#6ca4b5', fontWeight: 900 }}>
                <img src={props.item.image} alt={props.item.alt} className="oem-carousel"></img>
                <span id="carousel-id" className="carousel-title">{props.item.name}</span>
                {/* <p>{props.item.description}</p> */}
            </Paper>
        )
    }

    var items = [
        {
            name: i18n.t(k.OEM1),
            description: "OEM 1",
            image: img1,
            alt: "OEM 1"
        },
        {
            name: i18n.t(k.OEM2),
            description: "OEM 2",
            image: img2,
            alt: "OEM 2"
        },
        {
            name: i18n.t(k.OEM3),
            description: "OEM 3",
            image: img3,
            alt: "OEM 3"
        },
        {
            name: i18n.t(k.OEM4),
            description: "OEM 4",
            image: img4,
            alt: "OEM 4"
        },
        {
            name: i18n.t(k.OEM5),
            description: "OEM 5",
            image: img5,
            alt: "OEM 5"
        },
        {
            name: i18n.t(k.OEM6),
            description: "OEM  6",
            image: img6,
            alt: "OEM 6"
        },
        {
            name: i18n.t(k.OEM7),
            description: "OEM  7",
            image: img7,
            alt: "OEM 7"
        },
        {
            name: i18n.t(k.OEM8),
            description: "OEM  8",
            image: img8,
            alt: "OEM 8"
        }
    ]
    return (

        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '90px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={0} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.TRACKIQ)}</Typography>
                    <Typography className="subtitle" variant="h5">{i18n.t(k.PARTNER)}</Typography>
                </Paper>
            </Box>
            <div className="content-container">
                <Box className={classes.headline}
            >
                    <Carousel className="carousel"
                    indicators={false}
                    animation='fade'
                    fullHeightHover={false}
                    interval={4000}
                    //     navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    //         style: {
                    //             backgroundColor: '#647686',
                    //             borderRadius: '5px',
                    //             color: 'white',
                    //         }
                    //     }}
                    //     navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                    //         style: {
                    //             bottom: '0',
                    //             top: 'unset'
                    //         }
                    //     }}
                    //     NextIcon='next'             // Change the "inside" of the next button to "next"
                    //     PrevIcon='prev'             // Change the "inside of the prev button to "prev"
                    // NavButton={({ onClick, className, style, next, prev }) => {

                    //     return (
                    //         <Button onClick={onClick} className={className} style={style}>
                    //             {next && <ChevronRightIcon></ChevronRightIcon>}
                    //             {prev && <ChevronLeftIcon></ChevronLeftIcon>}
                    //         </Button>
                    //     )
                    // }}
                    >
                    {
                        items.map((item, i) => <Item key={i} item={item} />)
                    }
                    </Carousel>

            </Box>
            <Box className="home-cards">
                    <Card className="home-card" sx={{ maxWidth: 445, height: '545px', boxShadow: 3 }}>
                    <Typography
                        className={classes.cardItem}
                        gutterBottom
                        variant="h5"
                        component="div"
                        >
                        {i18n.t(k.OEM)}
                    </Typography>
                    <CardMedia
                        sx={{ height: 140 }}
                        image={img1}
                        title="OEM"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            className={classes.cardContent}>
                                {i18n.t(k.CONNECTED_CARD)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href="#/oem" size="small" className={classes.cardButton}>{i18n.t(k.LEARN_MORE)}</a>
                    </CardActions>
                    </Card>

                    <Card className="home-card" sx={{ maxWidth: 445, height: '545px', boxShadow: 3 }}>
                        <Typography
                            className={classes.cardItem}
                            gutterBottom
                            variant="h5"
                            component="div"
                        >
                            {i18n.t(k.FLEET)}
                        </Typography>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={corporate}
                            title="Frotas"
                        />
                        <CardContent>
                            <Typography
                                variant="body2"
                                className={classes.cardContent}>
                                {i18n.t(k.CORPORATE_HEAD)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <a href="#/fleet" size="small" className={classes.cardButton}>{i18n.t(k.LEARN_MORE)}</a>
                        </CardActions>
                    </Card>

                    <Card className="home-card" sx={{ maxWidth: 445, height: '545px', boxShadow: 3 }}>
                        <Typography
                            className={classes.cardItem}
                            gutterBottom
                            variant="h5"
                            component="div"
                        >
                            {i18n.t(k.RENTAL)}
                        </Typography>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={rental}
                            title="Locadoras"
                        />
                        <CardContent>
                            <Typography
                                variant="body2"
                                className={classes.cardContent}>
                                {i18n.t(k.RENTAL_HEAD)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <a href="#/rental" size="small" className={classes.cardButton}>{i18n.t(k.LEARN_MORE)}</a>
                        </CardActions>
                    </Card>

                    <Card className="home-card" sx={{ maxWidth: 445, height: '545px', boxShadow: 3 }}>
                        <Typography
                            className={classes.cardItem}
                            gutterBottom
                            variant="h5"
                            component="div"
                        >
                            {i18n.t(k.SOLUTIONS)}
                        </Typography>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={solutions}
                            title="Solutions"
                        />
                        <CardContent>
                            <Typography
                                variant="body2"
                                className={classes.cardContent}>
                                {i18n.t(k.PROFESSIONAL_CARD)}
                            </Typography>
                        </CardContent>
                        <CardActions >
                            <a href="#/solutions" size="small" className={classes.cardButton}>{i18n.t(k.LEARN_MORE)}</a>
                        </CardActions>
                    </Card>

            </Box>
                {sessionStorage.getItem('lng') === 'en' ?
                <img className={`${classes.splash} ${classes.imgFluid}`} src={cloud} alt="splash site trackiq" />
                :
                <img className={`${classes.splash} ${classes.imgFluid}`} src={cloud_pt} alt="splash site trackiq" />
                }
            <FooterContainer className="footer--pin" />
            </div>
        </React.Fragment>
    );
}