const k = require('./keys');

const portuguese =  {
  [k.TRACKIQ]: `trackIQ`,
  [k.ACCOUNT]: `Conta`,
  [k.LOGIN]: `Login no Sistema`,
  [k.HOME]: `Início`,
  [k.ERROR_LOGIN]: `Erro de Identificação no Login!`,
  [k.FORGOT]: `Esqueceu a Senha?`,
  [k.CHANGE_PASS]: `Troca de Senha`,
  [k.PASSWORD]: `Senha`,
  [k.RESET_PASSWORD]: `Reset Senha`,
  [k.CONFIRMATION]: `Confirmação`,
  [k.CONFIRMATION_HELPER]: `Confirmação de Senha deve ser igual a senha!`,
  [k.LOG]: `Login`,
  [k.EXIT]: `Sair`,
  [k.LOGOUT]: `Deseja sair do sistema?`,
  [k.CANCEL]: `Cancelar`,
  [k.SUBMIT]: `Entrar`,
  [k.PARTNER]: `Seu Parceiro de Tecnologia!`,
  [k.OEM]: `OEM`,
  [k.FLEET]: `Frotas`,
  [k.FLEET1]: `Gestão Inteligente de Frotas`,
  [k.FLEET2]: `Escalabilidade com Segurança`,
  [k.FLEET3]: `Relatórios de Gestão - BI`,
  [k.FLEET4]: `Gestão de Combustível`,
  [k.FLEET5]: `Gestão de Uso da Frota`,
  [k.RENTAL]: `Locadoras`,
  [k.FSERVICE]: `Serviços`,
  [k.SOLUTIONS]: `Soluções`,
  [k.SYSTEM]: `Sistema`,
  [k.COOKIE]: `Cookies`,
  [k.COOKIES]: `Utilizamos cookies para melhorar a sua experiência no nosso site. Ao continuar,
                 você concorda com este uso. Você pode configurar o seu navegador para bloquear
                  os cookies ou para ser informado quando eles são enviados. Saiba mais.`,
  [k.ACCEPT]: `Aceitar`,
  [k.DECLINE]: `Declinar`,
  [k.COPYRIGHT]: `© 2023 trackIQ, Todos os direitos reservados.`,
  [k.WORK]: `Trabalhe com a gente`,
  [k.ABOUT]: `Sobre`,
  [k.ABOUTUS]: `Sobre nós`,
  [k.STORY]: `História`,
  [k.VISION]: `Visão`,
  [k.VISION_HEAD]: `Ser a melhor escolha em serviços e tecnologias de telemática automotiva na percepção
                    de clientes e parceiros de nosso ecossistema.`,
  [k.MISSION]: `Missão`,
  [k.MISSION_HEAD]: `Ser Protagonista no desenvolvimento de soluções de telemática automotiva: `,
  [k.MISSION_HEAD1]: `•	Entregando a melhor experiência e o melhor resultado a nossos clientes;`,
  [k.MISSION_HEAD2]: `•	Inovando e Melhorando Sempre pelo contínuo uso do conhecimento e da tecnologia;`,
  [k.MISSION_HEAD3]: `•	Impactando positivamente na sustentabilidade e excelência de nossos clientes,
                      colaboradores, parceiros e a sociedade como um todo.`,
  [k.SERVICES]: `Serviços`,
  [k.PROFESSIONAL]: 'Profissionais',
  [k.CONSULTING]: 'Consultoria',
  [k.INTELLIGENCE]: 'Inteligência',
  [k.ELECTRICALS]: 'Veículos Elétricos',
  [k.CORPORATE_FLEET]: 'Frota Corporativa',
  [k.CONTACT]: `Contato`,
  [k.CONTACT_US]: `Contate-nos`,
  [k.CONTACT_US1]: `ENTRE EM CONTATO`,
  [k.YOUR_NAME]: `Nome *`,
  [k.YOUR_LASTNAME]: `Sobrenome *`,
  [k.YOUR_TITLE]: `Cargo`,
  [k.YOUR_COMPANY]: `Empresa`,
  [k.YOUR_E_MAIL]: `Your e-mail *`,
  [k.YOUR_PHONE]: `Telefone`,
  [k.REASON_FOR_YOUR_CONTACT]: 'Motivo do seu contato',
  [k.YOUR_MESSAGE]: `Mensagem`,
  [k.OTHERS]: `Outros`,
  [k.SEND_MESSAGE]: 'Envie sua mensagem',
  [k.COMPANY_NAME]: 'trackIQ - Technology Partner',
  [k.COMPANY_ADDRESS]: 'Avenida Omega, 219 - Cj 34',
  [k.CITY]: 'Barueri',
  [k.STATE]: 'São Paulo',
  [k.COUNTRY]: 'Brasil',
  [k.ZIP]: '06472-005',
  [k.PHONE]: 'Telefone: +55 11-4191-2267',
  [k.WHATS]: 'Whatsapp: +55 11-98133-8108',
  [k.FOLLOW_US]: 'Siga-nos',
  [k.EMAIL]: 'ascalmon@trackiq.com.br',
  [k.POLICIES]: 'Politicas',
  [k.PRIVACY_POLICY]: 'Privacidade',
  [k.COOKIES_POLICY]: 'Cookies',
  [k.TERMS_OF_SERVICE]: 'Termos de Serviço',
  [k.LEARN_MORE]: `SAIBA MAIS`,
  [k.OEM1]: `Connectividade`,
  [k.OEM2]: `Design Único - Apps`,
  [k.OEM3]: `Serviços de Mobilidade`,
  [k.OEM4]: `Telemática`,
  [k.OEM5]: `Aprendizado de Máquina - ML`,
  [k.OEM6]: `Customizações`,
  [k.OEM7]: `Inteligência Artificial - AI`,
  [k.OEM8]: `Veículos Elétricos`,
  [k.NEXT]: `Prox.`,
  [k.PREVIOUS]: `Ant.`,
  [k.CONNECTIVITY]: `Conectividade`,
  [k.WHAT_WE_DO]: `O que Fazemos`,
  [k.SOME_OF_OUR_WORK]: `ALGUNS DE NOSSOS TRABALHOS`,
  [k.STORY_ABOUT_US]: `Nossa História`,
  [k.ABOUT_PART_1_0]: `A `,
  [k.ABOUT_PART_1]: ` foi criada para prover serviços e soluções de Mobilidade e Telemática Veicular
                    a clientes corporativos tais como Montadoras Automotivas (OEMs), Grandes Frotistas
                     e Locadoras.`,
  [k.ABOUT_PART_2]: ` somos movidos pela melhoria contínua, gerando novos conhecimentos, práticas, algoritmos
                     e modelagens que contribuem para o avanço disruptivo e a inovação do ecossistema automotivo,
                     através da aplicação de tecnologias em nuvem com escalabilidade, segurança e alta disponibilidade.`,
  [k.ABOUT_PART_2_00]: `A larga experiência de seus gestores e consultores simplifica a entrega da melhor experiência
                      e o melhor resultado em termos de atendimento diferenciado e tecnologia a nossos clientes. `,
  [k.ABOUT_PART_2_0]: `Na `,
  [k.ABOUT_PART_3]: `Nosso modelo de gestão utilizando tecnologias de mineração, análise, compilação de dados e
                    integração de diferentes parceiros do ecossistema automotivo sintetiza, de forma simplificada,
                    os principais indicadores de gestão dos ativos móveis, premissa básica para que nossos clientes
                    tomem decisões baseadas em evidências, de forma e inequívoca e cuidadosa.`,
  [k.ABOUT_PART_4_0]: `Na `,

  [k.ABOUT_PART_4]: ` atuamos nas áreas de M2M (Conexão entre máquinas) e IoT (Internet das Coisas) orientado ao
                    segmento automotivo, utilizando soluções embarcadas em veículos para rastreamento, monitoramento
                    por dados (telemetria veicular), monitoramento por imagem (vídeo-telemetria) e diagnose veicular
                    (inteligência artificial).`,
  [k.ABOUT_PART_5]: `Nossas soluções ajudam montadoras automotivas na digitalização de seus serviços, na conectividade
                    e interação com seus clientes, na redução de custos de garantia de seus veículos e na monetização
                    dos dados advindos de seus veículos, fidelizando seus clientes e conectando-os as suas marcas.`,
  [k.ABOUT_PART_6]: `Valorizamos um ambiente ágil, colaborativo, transparente e ético com nossos clientes, colaboradores
                     e parceiros, trabalhando e dialogando para fazer sempre melhor.`,
  [k.ABOUT_PART_7]: `Queremos ser a melhor escolha de telemática automotiva na percepção de nossos clientes, atuando
                    como protagonista no desenvolvimento de soluções e serviços inovadores para o segmento automotivo de
                    veículos conectados.`,
  [k.ABOUT_PART_8]: ``,
  [k.ABOUT_PART_9]: ``,
  [k.COMPANY_VALUES_HEAD]: `VALORES:`,
  [k.COMPANY_VALUES1]: `•	EXCELÊNCIA:
                        o	Fazemos sempre melhor, a cada dia, para entregar continuamente a nossos clientes o melhor resultado e a melhor experiência.`,
  [k.COMPANY_VALUES2]: `•	AGILIDADE:
                        o	Ouvimos e entendemos nossos clientes, atuando de forma rápida e objetiva para atender suas necessidades.
`,
  [k.COMPANY_VALUES3]: `•	RESPEITO:
                        o	Somos sempre empáticos e colaborativos, trabalhando, aprendendo e ensinando uns com os outros, de forma aberta e Ética.
`,
  [k.COMPANY_VALUES4]: `•	INOVAÇÃO:
                        o	Melhoramos continuamente nossa tecnologia e processos, gerando avanços disruptivos e incrementais ao ecossistema automotivo.
`,
  [k.COMPANY_VALUES5]: `•	RESPONSABILIDADE:
                        o	Cuidamos de criar e manter parcerias sólidas e duradouras uns com os outros, com nossos clientes, parceiros e com nossa comunidade.
`,
  [k.IT_IS_OUR_COMMITMENT_AND_MARKET]: `É o nosso compromisso e abordagem focada no mercado
                  que nos permite ajudar nossos clientes a reduzirem custos,
                  proteger ativos, ganhar eficiência, melhorar recursos na
                  utilização, minimizar fraudes e, o mais importante: salvar vidas, impedindo
                  acidentes.`,
  [k.PASS_MESSAGE]: `Senha de conter no mínimo 5 caracteres`,
  [k.TELEMATICS]: `Telemática`,
  [k.TELEMATICS_TITLE]: 'O poder de nossa solução fim-a-fim',
  [k.TELEMATICS_HEADER]: `A plataforma baseada em nuvem da trackIQ chama-se
                  trackCloud - plataforma de software inteligente baseada
                  em nuvem que normaliza diferentes fontes de dados brutos em um
                  centro de informações bem estruturado, gerando insights e informações
                  formatadas para alimentar os OEMs da indústria automotiva,
                  a rede de revendedores , Clientes OEM e principais parceiros
                  de negócios como seguradoras, grupos financeiros e outros players
                  automotivos relevantes.`,
  [k.TELEMATICS1]: `Arquitetura flexível pronta a ser integrada com as aplicações de manufatura,
                    Marketing, Vendas, Planeamento entre outras aplicações específicasdos OEMs`,
  [k.TELEMATICS2]: `Ferramenta poderosa que permite aos OEMs personalizarem e desenvolverem
                    funcionalidades únicas aos seus veículos. Implementações simples, em fases,
                    desbloqueando informações de dados ainda ocultos no novo ambiente do automóvel digital.
                    A flexibilidade do sistema permite o desenvolvimento de aplicações de serviço para frotas e mobilidade.
                    Aplicações de serviços, personalizadas de acordo com as suas necessidades, para
                    'Machine Learning e Artificial Intelligence'.`,
  [k.TELEMATICS3]: `Gerencia conexões seguras à bases de dados profissionais, criadas especificamente
                    para armazenar os dados dos clientes dos OEMs em um ambiente seguro, garantindo
                    privacidade dos dados dos seus clientes.`,
  [k.TELEMATICS4]: `Melhorias no Negócio dos OEMs através da harmonização, estruturação e correlação de
                    dados brutos do veículo com informações relevantes, toda a sua atividade pode ser melhorada,
                    permitindo melhorias significativas no lançamento de um novo veículo.`,
  [k.TELEMATICS5]: `Construir sua própria aplicação de forma personalizada e escalável para gerar novas receitas
                     recorrentes para o negócio e criando oportunidades para monetização de dados e implementação
                     de serviços digitais.`,
  [k.TELEMATICS6]: `Integração total com os módulos electrónicos do veículo através de dispositivos que detectam
                    alterações no comportamento do mesmo e alimentam o trackIQCloud com informações para o
                    condutor e para as Montadoras de Veículos.`,
  [k.TELEMATICS7]: ``,
  [k.TELEMATICS8]: ``,
  [k.TCU1]: `High performance quad core processor.`,
  [k.TCU2]: `Bluetooth 4.1 (Low Energy).`,
  [k.TCU3]: `USB, Ethernet,WiFi (802.11 a/b/g/n) 2.4 GHz with Hotspot.`,
  [k.TCU4]: `Scalable NAD 4G Cat12 to 5G`,
  [k.TCU5]: `Scalable eMMC - 512MB to 1GB and RAM - 4GB to 16GB`,
  [k.TCU6]: `Embedded LINUX Operating System `,
  [k.TCU7]: `Options to DSRC.`,
  [k.TCU8]: `Options to Ethernet switch and C-V2X`,
  [k.DONGLES1]: `High performance quad core Qualcomm processor.`,
  [k.DONGLES2]: `Bluetooth 4.1 (Low Energy), 4G/LTE.`,
  [k.DONGLES3]: `USB, Ethernet,WiFi (802.11 a/b/g/n) 2.4 GHz with Hotspot.`,
  [k.DONGLES4]: `GPS, A-GPS, Accelerometer/Gyroscope.`,
  [k.DONGLES5]: `OBD-II or CAN interface, Full suite of OBD-II protocols, Low power consumption.`,
  [k.DONGLES6]: `Battery back-up.`,
  [k.DONGLES7]: `CAN security, Certifications: FCC, PTCRB`,
  [k.DONGLES8]: `Secure Over-the-Air updates, Mobile application for consumer`,
  [k.CUSTOMIZATIONS_TITLE]: 'Customizações',
  [k.CUSTOMIZATIONS_HEAD]: `A nova era dos veículos e serviços conectados está
                          transformando o comportamento do cliente em uma
                          velocidade sem precedentes. A maneira como você se
                          comunica e interage com seus clientes faz toda a
                          diferença para continuar sendo relevante no futuro.
                          Nossos serviços personalizados e de mobilidade ajudam
                          você a criar seu próprio aplicativo (aplicativos móveis)
                          de maneira personalizada e escalável para gerar novas
                          oportunidades de negócios de receita para monetização
                          de dados e implementação de serviços digitais.`,
  [k.CUSTOMIZATIONS1]: `Alguns serviços que podem ser gerados para os seus clientes:`,
  [k.CUSTOMIZATIONS2]: `Saúde do veículo: Lista de controle diário do veículo. O seu cliente
                        sabe que o veículo está seguro para a próxima viagem..`,
  [k.CUSTOMIZATIONS3]: `Curfew - Recolher obrigatório: Receba alertas se o seu veículo
                        começar a circular em horários proibidos, independentemente do local onde se encontra.`,
  [k.CUSTOMIZATIONS4]: `Comportamento do condutor: Melhora os preços das apólices de seguro anuais dos clientes.
                        Ajude os seus clientes e famílias a ter segurança no seu dia-a-dia.
                        Forneçe o comportamento de condução na última viagem e feedback de condução inteligente.`,
  [k.CUSTOMIZATIONS5]: `Hora prevista de chegada: Calcule a sua hora de chegada e partilhe com os seus
                        parentes ou amigos utilizando o melhor sistema de encaminhamento.`,
  [k.CUSTOMIZATIONS6]: `Manutenção preventiva:  Lembrete de manutenção. Digital
                        Calendário Digital de manutenção do veículo com as concessionárias.`,
  [k.CUSTOMIZATIONS7]: `Âncora: Proteja o seu veículo! Receba alertas em tempo real se o seu
                        veículo começar a mover-se. Importante seu seguro saber que se preocupa com o seu bem.`,
  [k.CUSTOMIZATIONS8]: `Interação de grupo: Partilhe notificações pré-definidas com a sua família
                        ou grupo de amigos sobre si e o seu automóvel durante as suas viagens.`,
  [k.CUSTOMIZATIONS9]: `Internet e entretenimento: Estar atualizado! Expandir a experiência de conetividade
                        do Cliente em seu veículo similarmente a experiência em casa e no escritório.
                        Oferecer-lhes Internet de alta velocidade (Wi-Fi, BT, LTE, 5G) incorporada ao veículo.`,
  [k.CUSTOMIZATIONS10]: `Gestão de Recall: Poupe tempo e recursos na publicidade Recall através da comunicação direta
                        com os proprietários de veículos relevantes em caso de Recalls.`,
  [k.CUSTOMIZATIONS11]: `Backup de abertura do veículo sem chave: Abra o seu automóvel com a sua aplicação com código
                        de segurança, caso perca a chave original.`,
  [k.CUSTOMIZATIONS12]: `Market Place: Permita que o seu cliente obtenha tudo da sua aplicação.
                         Envolva-os com promoções de combustível, estacionamento, partes e peças
                         usando informações de dados em tempo real.`,
  [k.CUSTOMIZATIONS13]: `Notificação de colisão: Deixe o seu cliente programar a notificação automática em caso de colisão! `,
  [k.CUSTOMIZATIONS14]: `Gestão de veículos eléctricos: Manter os clientes atualizados sobre
                         local de recarga e autonomia do veículo.`,
  [k.CUSTOMIZATIONS15]: `Tudo o que pensa que pode ser explorado sobre as informações do veículo. A nossa experiência em
                         personalizações lhe permitem sonhar com a APP que os seus clientes merecem.`,
  [k.MOBILITY_SERVICES]: 'Serviços de Mobilidade',
  [k.MOBILITY_TITLE]: 'Mobilidade - Oportunidades para os OEMs automotivos',
  [k.MOBILITY_HEAD]: `A transformação da indústria automotiva leva os OEMs a diversificar
                    a oferta de negócios além da venda de veículos. Financiamentos de
                    Veículo de curto e longo prazo, compartilhamento de carros e
                    outros usos colaborativos de veículos criam novos modelos de negócios
                    e levam os OEMs à era dos serviços de mobilidade.`,
  [k.MOBILITY1]: `trackIQ Plataforma Baseada em Nuvem - trackIQCloud, ajuda os OEMs a operar e gerenciar serviços de mobilidade
                em tempo real, garantindo total visibilidade, segurança e controle da frota.`,
  [k.MOBILITY2]: `APIs abertas envolvem diferentes parceiros do ecossistema de mobilidade tais como: Sistemas CRM Especializados,
                  Sistemas de Emissão de Multas, Plataformas de 'Car Sharing', Cartão de Combustível para Empresas,
                  instituições financeiras e seguradoras parceiras, entre outros a consumir e disponibilizar dados valiosos.
                  trackIQCloud harmoniza todos os dados em um único Painel de Business Intelligence (BI) de mobilidade personalizada
                  de serviços.`,
  [k.MOBILITY3]: `O mecanismo de 'aprendizado de máquina' (ML) ajuda aos OEMs, aos serviços de mobilidade dos revendedores OEM e aos
                  Parceiros de serviços e outros parceiros de negócios dos OEMs de Mobilidade  para
                  usar informações valiosas para ter insights acionáveis ​​para impactar substancialmente o desempenho dos negócios,
                  o crescimento sustentável dos negócios e a satisfação dos clientes.`,
  [k.MOBILITY4]: `Diagrama de blocos do painel de serviço.`,
  [k.MOBILITY5]: ``,
  [k.MOBILITY6]: ``,
  [k.MOBILITY7]: ``,
  [k.MOBILITY8]: ``,
  [k.MOBILITY9]: ``,
  [k.MOBILITY10]: ``,
  [k.MOBILITY11]: ``,
  [k.CONNECTED_VEHICLES]: 'Veículos Conectados',
  [k.CONNECTED_TITLE]: `Seu Parceiro em 'Soluções Connectadas'`,
  [k.CONNECTED_HEADER]: `A trackIQ está seriamente trabalhando no processo
                        de definição universal de 'Veículo Conectado'.
                        A tecnologia está no coração dos OEMs da indústria automotiva
                        e cresce a um ritmo que ainda não está claro onde
                        a definição de 'carro conectado' será concluída.
                        Fabricantes de automóveis vendem "Carros Conectados"
                        que vão desde um simples conectado à internet
                        através de conexão celular, com conjunto limitado de recursos,
                        a um veículo totalmente conectado necessário em todas as etapas
                        da tecnologia de 'Autonomous-driving'.
                        Um verdadeiro carro conectado deve ser muito mais do que um simples
                        Hotspot WiFi e se tornar parte da vida das pessoas, conectadas o tempo
                        todo e compartilhar informações conforme desejado em um mundo real de conexão.`,
  [k.CONNECTED_CARD]: `A trackIQ está seriamente trabalhando no processo
                        de definição universal de 'Veículo Conectado'.
                        A tecnologia está no coração dos OEMs da indústria automotiva
                        e cresce a um ritmo que ainda não está claro onde
                        a definição de 'carro conectado' será concluída. Fabricantes de
                        automóveis vendem "Carros Conectados" que vão desde um simples
                        conectado à internet através de conexão celular, com conjunto
                        limitado de recursos, a um veículo totalmente conectado necessário
                        em todas as etapas da tecnologia de 'Autonomous-driving'.`,
  [k.WHAT1]: `Para os usuários de veículos:`,
  [k.WHAT2]: `   -  Conectados à Internet 100% do tempo.`,
  [k.WHAT3]: `   -  Transição suave - do estilo de vida Casa / Escritório para o 'Connected car'.`,
  [k.WHAT4]: `   -  Interação do usuário com o mundo digital de forma integrada.`,
  [k.WHAT5]: `Para os fabricantes de veículos:`,
  [k.WHAT6]: `   -  Dispositivos Telemáticos confiáveis para uma conexão segura e integrada.`,
  [k.WHAT7]: `   -  Instalação, configuração e conexão a nuvem simples tipo 'Plug and Play'.`,
  [k.WHAT8]: `   -  Diagnoses, BI, Análise de dados, FOTA/OTA em único ambiente.`,
  [k.DESIGN]: 'Design',
  [k.APP_SYSTEM_DESIGN]: `APP / DESENHO DE SISTEMAS`,
  [k.UNIQUE_DESIGN]: `DESIGN ÚNICO`,
  [k.FOR_ALL_DEVICES]: `Para Todos os Dispositivos`,
  [k.HEADUNITS]: `Integração com os 'HeadUnits' dos OEMs - Dispositivos 'Infotainment'`,
  [k.TABLETS]: `Tablets para o monitoramento Profissional de sues veículos.`,
  [k.SPECIAL]: `Dispositivos espaciais com integração com OSs para suportar seus projetos.`,
  [k.OS]: `Sistemas Operacionais: iOS e Android.`,
  [k.DESIGN_TECHNOLOGY]: 'Tecnologia e Design',
  [k.DESIGN_HEAD]: `A trackIQ possui expertise no desenvolvimento
                    de soluções personalizadas para o seu 'Veículo Conectado',
                    independentemente do dispositivo que você escolheu: Sistemas
                    de 'Infotainment', 'Headunits', 'Smartphones', 'Tablets' para
                    os principais sistemas operacionais disponíveis.`,
  [k.DESIGN1]: `Conexão com In-Vehicle WiFi - Hotspot.`,
  [k.DESIGN2]: `OEM's 'Look and Feel' Customizações HMI.`,
  [k.DESIGN3]: `UX, UI, HMI customizados de acordo com a 'personalidade'do veículo.`,
  [k.DESIGN4]: `Modularidade, Flexibilidade, Scalabilidade.`,
  [k.APPS]: "APPS",
  [k.DESIGN_APP1]: `Design e customizações das Apps baseadas nos seguintes fatores:`,
  [k.DESIGN_APP2]: `APP Skin - OEM Brand, cores, logos, fontes e tamanho de letras.`,
  [k.DESIGN_APP3]: `App Identidade - Classico, Esportivo, Familia, Serviços, Frotas de veículos, etc.`,
  [k.DESIGN_APP4]: `App uso - B2B - Serviços Frota Privada.`,
  [k.DESIGN_APP5]: `App uso - B2C - Usuários dos veículos e serviços.`,
  [k.DESIGN_APP6]: `App pacotes de funcionalidades: Vários pacotes para satisfazer os clientes dos OEMs.`,
  [k.DESIGN_APP7]: `App Funcionalidades para os OEMs - Serviços de Inteligência.`,
  [k.DESIGN_APP8]: `App Funcionalidades para os usuários - Centenas de funcionalidades em
                    diversos acessíveis pacotes de serviços.`,
  [k.OUR_APP]: "Nossa App",
  [k.DESIGN_OUR_APP1]: `UX, UI Desenhados de acordo com as especificações dos OEMs.`,
  [k.DESIGN_OUR_APP2]: `'Look and Feel' basado nas categorias dos veículos;`,
  [k.DESIGN_OUR_APP3]: `Ferramentas de Marketing embarcadas para serviços e suporte.`,
  [k.DESIGN_OUR_APP4]: `Sistemas Operacionais: iOS e Android.`,
  [k.DESIGN_OUR_APP5]: `Saúde do Veículo em suas mãos.`,
  [k.DESIGN_OUR_APP6]: `Informações em Grupos  para segurança, informação e diversão.`,
  [k.DESIGN_OUR_APP7]: `Serviços sempre disponíveis para seu suporte.`,
  [k.DESIGN_OUR_APP8]: `Emergências: Mecânicas, Medicas e de Segurança.`,
  [k.ARTIFICIAL_INTELLIGENCE]: `Inteligência Artificial`,
  [k.ARTIFICIAL_INTELLIGENCE_AI_S]: `Sistemas de inteligência artificial (IA), que usam dados e
                  algoritmos para imitar as funções cognitivas da mente humana,
                  e ter a capacidade de aprender e resolver problemas
                  independentemente, estão sendo implantados rapidamente em uma variedade de
                  indústrias e casos de uso. A indústria automotiva está entre as
                   indústrias na vanguarda do uso da IA ​​para imitar, aumentar,
                   e apoiar as ações dos seres humanos, enquanto simultaneamente,
                   aproveitando os ultra rápidos tempos de reação, identificar com
                   precisão o comportamento de sistemas baseados em máquinas. De fato, hoje
                   veículos semi-autônomos e veículos totalmente autônomos
                   no futuro, dependerão fortemente dos sistemas de IA.`,
  [k.AI1]: `Os dados e os algoritmos imitam as funções cognitivas da mente humana.`,
  [k.AI2]: `Capacidade para aprender e resolver problemas de forma autonoma.`,
  [k.AI3]: `Implementado numa variedade de indústrias e casos de utilização.`,
  [k.AI4]: `Tempos de reação avançados - precisão dos sistemas baseados em máquinas.`,
  [k.AI5]: `A indústria automtiva utiliza a IA para:`,
  [k.AI6]: `   -  Para imitar, aumentar e apoiar as ações dos seres humanos.`,
  [k.AI7]: `   -  Tornar a vida do motorista no carro mais conveniente e segura.`,
  [k.AI8]: `   -  Assistentes veiculares, orientados pelo processamento de linguagem natural.`,
  [k.AI9]: `   -  Sistemas para responder a comandos de voz e inferir as ações a serem tomadas.`,
  [k.INTELLIGENCE]: 'Serviços de Inteligência',
  [k.INTELLIGENCE_TITLE]: `Levam veículos e dispositivos inteligentes a uma 'Experiência Digital'`,
  [k.INTELLIGENCE_HEAD]: `'trackIQ' - 'Intelligence Services', conecta as
                        redes sem fio existentes e a futura rede 5G que permite que
                        veículos e dispositivos inteligentes, instalados pelos OEMs
                        da indústria automotiva, se comuniquem em modo inteligente
                        e analisem dados em tempo real para otimizar a experiência
                        do veículo. Os 'Serviços de Inteligência' permitem que os
                        OEMs iniciem com segurança projetos desafiadores com o
                        suporte do nosso pacote de soluções de Inteligência.
                        A seguir, é apresentada uma visão geral dos serviços da
                        trackIQ - 'Intelligence Services':`,
  [k.INTELLIGENCE1]: `Gerenciamento de Recall: Economize tempo e dinheiro gastos com
                     anúncios e gestão de 'Recalls' através de comunicação direta com
                     os clientes envolvidos. Estruturação de relatórios de acompanhamento e
                     aceitação digital dos clientes.`,
  [k.INTELLIGENCE2]: `Taxas de roubos e colisões por região assim como taxa de utilização de
                    serviços de emergências médicas e mecânicas por região dentre outros
                    serviços de relatórios inteligentes.`,
  [k.INTELLIGENCE3]: `Business Intelligence: Desenvolvimento de BIs customizados para
                    operações e gerenciamento do negócio.`,
  [k.INTELLIGENCE4]: `Gestão veículos elétricos: Mantenha os clientes informados sobre as
                    próximas estações (privadas ou públicas) de recarga da bateria.
                    Noticar os clientes sobre autonomia e tempo da carga.`,
  [k.INTELLIGENCE5]: `Gerenciamento de Garantias: Analise da diagnose do veículo para
                    mecanismos eficientes de alerta de situações críticas aos condutores,
                    concessionárias e montadora de veículos economizando desta forma enormes
                    recursos.`,
  [k.INTELLIGENCE6]: `Gestão de Marketing: Suporte aos OEMs na gestão de 'leads',
                    analise das condições de veículos para valorização nas trocas,
                    informação de sugestões na direção do veículo, regiões de uso do
                    veículo, promoções de items importantes para o veículo, etc.
                    Ex: Troca de óleo / pneus baseado na kilometragem - informa
                    aos clientes sobre promotioções ou benefícios da troca no prazo
                    e em uma concessionária.`,
  [k.INTELLIGENCE7]: ``,
  [k.INTELLIGENCE8]: ``,
  [k.INTELLIGENCE9]: ``,
  [k.INTELLIGENCE10]: ``,
  [k.INTELLIGENCE11]: ``,
  [k.TECHNOLOGY]: 'Tecnologia',
  [k.MACHINE_LEARNING]: `Aprendizado de Máquinas`,
  [k.MACHINE_LEARNING_FOR_THE_OEM_W]: `Aprendizado de Máquina para o mundo OEM`,
  [k.ML1]: `O ML está ajudando a trazer transformações operacionais e comerciais.`,
  [k.ML2]: `O Big Data e o aprendizado de máquina estão remodelando os processos das empresas automotivas.`,
  [k.ML3]: `Maior nível de precisão na tomada de decisões e melhor desempenho.`,
  [k.ML4]: `Inteligência no veículo reforçando a comunicação entre os sistemas de navegação,
            interfaces homem-máquina e plataformas de localização para informar aos motoristas sobre
            situações perigosas nas estradas.`,
  [k.ML5]: `Manutenção preditiva de veículos com coleta de dados de módulos no veículo e
            aplicação de análise preditiva para alertar os motoristas sobre manutenção urgente ou planejada.`,
  [k.ML6]: `Roteamento inteligente e otimização de POIs para ensinar a um veículo as rotas e os pontos de interesse mais usados
            com inteligência artificial para o setor automotivo.`,
  [k.ML7]: ``,
  [k.ML8]: ``,
  [k.ML9]: ``,
  [k.PROFESSIONAL_TITLE]: `Serviços Profissionais para seus clientes`,
  [k.PROFESSIONAL_HEAD]: `Na era digital, os serviços precisam ser simples, amigáveis
                        e automatizados. Novas tecnologias trazem novos tipos de clientes,
                        que esperam respostas rápidas. Os clientes estão mais conectados
                        à tecnologia e esperam ter tudo ao alcance de um botão.
                        O Serviço trackIQ 'Professional Services' é um centro de
                        suporte 24 horas por dia, 7 dias por semana disponível para
                        atender o cliente do OEM da indústria automotiva com base
                        em procedimentos estabelecidos, com um SLA (Service Level Agreement)
                        confiável e scripts definidos com os clientes automotivos.
                        O serviço de 'Veículo Conectado' deve se unir aos Serviços de
                        Atendimento ao Cliente para suporte e emergência durante o
                        horário de trabalho ou 24 x 7 hs.`,
  [k.PROFESSIONAL_CARD]: `Na era digital, os serviços precisam ser simples, amigáveis
                        e automatizados. Novas tecnologias trazem novos tipos de clientes,
                        que esperam respostas rápidas. Os clientes estão mais conectados
                        à tecnologia e esperam ter tudo ao alcance de um botão.
                        O Serviço trackIQ 'Professional Services' é um centro de
                        suporte 24 horas por dia, 7 dias por semana disponível para
                        atender o cliente do OEM da indústria automotiva com base
                        em procedimentos estabelecidos, com um SLA (Service Level Agreement)
                        confiável e scripts definidos com os clientes automotivos.`,
  [k.PROFESSIONAL1]: `Suporte e treinamento dos usuários na utilização dos Apps
                    para otimização no uso dos recursos disponíveis.`,
  [k.PROFESSIONAL2]: `Emergências Médicas: Suporte ao usuário conectando-se aos seus
                    familiares em caso de situacões médicas inesperadas ou acidentes.`,
  [k.PROFESSIONAL3]: `Emergências Mecânicas: Suport aos clientes em caso de problemas
                    mecânicos com o veículo. Veículos nas concessionárias ao invés de
                    terceiros não qualificados.`,
  [k.PROFESSIONAL4]: `Recuperação de veículos roubados: em paises com altas taxas de
                    roubos de veículos, oferecemos serviço de recuperação 24 x 7 hs.
                    Serviços de prevenção podem informar sobre fronteiras ou áreas
                    de risco e trazem importante redução no custo das apólices de seguro.`,
  [k.PROFESSIONAL5]: `Campanhas de Marketing: Suporte aos clientes dos OEMs  durante
                    campanhas de Marketing enviadas ao App dos usuários.`,
  [k.PROFESSIONAL6]: `Serviços de Mobilidade: Suporte aos clientes dos serviços de mobilidade
                    24 x 7 hs - Centro de emergências.`,
  [k.PROFESSIONAL7]: `Concierge: Serviço para os clientes durante horário comercial ou 24 x 7 hs.`,
  [k.PROFESSIONAL8]: ``,
  [k.PROFESSIONAL9]: ``,
  [k.CONSULTING_TITLE]: `O melhor caminho para seu 'Veículo Conectado'`,
  [k.CONSULTING_HEAD]: `A equipe 'trackIQ' tem o conhecimento e a
                      experiência para ajudar os OEMs da indústria automotiva
                      em todo o processo de projetar uma estratégia de 'Serviços
                      Conectados' e Serviços de Mobilidade. Suporte de design e
                      desenvolvimento até a implantação do projeto. Abaixo os
                      serviços que podemos oferecer aos OEMs da indústria automotiva:`,
  [k.CONSULTING1]: `Customização e definição dos pacotes de serviços de 'Connected Vehicle'
                  e 'Mobility Service'.`,
  [k.CONSULTING2]: `Procedimentos de ativação de TCUs em ambiente da fábrica e validação
                  e testes de connectividade com a Nuvem.`,
  [k.CONSULTING3]: `Integração com sistemas legados das montadoras de veículos.`,
  [k.CONSULTING4]: `Definição do pacote de dados dos SIM Card com gerenciamento dos mesmos.`,
  [k.CONSULTING5]: `Monetização do negócio de 'Connected Vehicle' - Dados embarcados disponíveis em tempo real.`,
  [k.CONSULTING6]: `Metodologias de ativação, atualização, melhorias dos serviços do veículo.`,
  [k.CONSULTING7]: `Desenvolvimento e integração com o ecosistema automotivo.`,
  [k.CONSULTING8]: `Integração de plataformas de serviços de terceiros - Ecosistema.`,
  [k.CONSULTING9]: `Serviço ao cliente / Assinatura de serviços.`,
  [k.CONSULTING10]: `Desenho e desenvolvimento de Apps customizadas.`,
  [k.CONSULTING11]: `Harmonização dos dados do veículo e muito mais ....`,
  [k.ELECTRIC_HEAD]: `trackIQ 'Intelligence Services', conecta as redes sem fio existentes e futuras redes
                      sem fio 5G que permitem que veículos e dispositivos inteligentes, instalados pelos OEMs,
                       se comuniquem em modo inteligente e analisem dados em tempo real para otimizar a
                       experiência do veículo. Os veículos elétricos, semiautônomos e autônomos precisam de
                       supervisão para oferecer uma experiência confiável e agradável aos usuários.
                       A trackIQ está pronta para desempenhar esse papel nesse novo e empolgante segmento.
                       Veja abaixo uma visão geral dos serviços para veículos elétricos da trackIQ:`,
  [k.ELECTRIC1]: `Monitoramento de falhas de hardware e software. Os sistemas eletrônicos falham devido a falhas em sensores,
                  ruído nos sinais e erros de software. Os veículos elétricos certamente apresentarão falhas que
                  a trackIQ pode analisar dados e tomar medidas rápidas para evitar situações de risco.`,
  [k.ELECTRIC2]: `Gerenciamento de veículos elétricos: Manter os clientes atualizados sobre o local de carregamento
               (parceiros privados e OEM atualizados sobre os principais locais para a instalação do carregador.
                Notificar o cliente sobre o tempo restante de carregamento.`,
  [k.ELECTRIC3]: `Serviços fornecidos pelo trackIQ: Relatórios de bateria, autonomia restante
                Análise de dados do motorista, notificações de DTCs, alertas personalizados, relatórios de quilometragem,
                diagnósticos remotos, monitoramento do gerenciamento da garantia, OTA / FOTA, status do carregador,
                locais públicos/privados de carregamento, redefinições remotas do carregador, carregamento inteligente,
                Melhor rota com base nos locais do carregador etc.`,
  [k.ELECTRIC4]: `Integração com o sistema de monitoramento de bateria. O BMS fornece informações de status em tempo real
                aos proprietários de veículos, envia essas informações para a nuvem para serem processadas
                e acionar ações para oferecer a melhor experiência de usuário aos proprietários de veículos.`,
  [k.ELECTRIC5]: ``,
  [k.ELECTRIC6]: ``,
  [k.ELECTRIC7]: ``,
  [k.ELECTRIC8]: ``,
  [k.ELECTRIC9]: ``,
  [k.ELECTRIC10]: ``,
  [k.ELECTRIC11]: ``,
  [k.CORPORATE]: `Frota Corporativa`,
  [k.CORPORATE_TITLE]: 'Parceiro dos Gestores de Frotas Corporativas',
  [k.CORPORATE_HEAD]: `A marca, a imagem e a reputação são uma longa jornada a ser estabelecida pelas
                      corporações na mente de seus clientes. E os devidos cuidados com suas frotas, seus
                      condutores (colaboradores) e com o atendimento a seus clientes finais de forma
                      segura torna-se um item essencial dessa jornada.`,
  [k.CORPORATE1]: `Pensando nisso e tratando segurança como item prioritário,
                  a trackIQ hospeda sua plataforma em nuvem em provedores globais,
                  em ambiente seguro, confiável, escalável e estável para executar
                  aplicativos automotivos.`,
  [k.CORPORATE2]: `Os ambientes de provedores globais são continuamente atualizados para torná-los
                  sempre mais seguros a fim de atender ao regulamento geral da lei de proteção de dados
                  em nível global. Nossas interfaces com outros parceiros do ecossistema automotivo
                  (APIs) e nossos produtos embarcados em veículos utilizam as mais modernas
                  criptografias a fim de garantir a segurança das informações fim a fim.`,
  [k.CORPORATE3]: `Realizamos testes de penetração contínuos e abrangentes com empresas terceirizadas
                  especializadas para mitigar riscos e garantir a evolução contínua da segurança da
                  plataforma. Os dados são armazenados em contas criptografadas com 3 cópias, e são
                  replicados em outros sites de backup de recuperação de desastres localizado nos EUA.`,
  [k.CORPORATE4]: `A trackIQ suporta corporações e suas frotas a atingir excelência na prestação de serviços,
                  com relatórios, gráficos, alertas e integrações com outros parceiros do ecossistema automotivo,
                  a fim de extrair informações consolidadas sobre seus veículos, seus motoristas e sobre a
                  eficiência de sua operação.`,
  [k.CORPORATE5]: `Além dos serviços de segurança de seus ativos móveis (monitoramento e recuperação de veículos
                  roubados), serviço essencial em países com alto índice de sinistralidade de veículos, as seguintes
                   funcionalidades ou conjuntos de funcionalidades estão disponíveis na plataforma, dependendo do
                   pacote de serviço e equipamento embarcado contemplado nas soluções:`,
  [k.CORPORATE6]: `Rastreamento ativo utilizando diferentes tecnologias embarcadas (rastreador, computador de bordo, telemetria por vídeo)`,
  [k.CORPORATE7]: `Identificação de condutor por i-button ou RFID com possibilidade de bloqueio do veículo em caso de não identificação.`,
  [k.CORPORATE8]: `Mapas digitais com diferentes camadas (satelital, tráfego etc.) com ferramentas rápidas de cadastro de pontos de
                  interesse, cercas eletrônicas, etc.`,
  [k.CORPORATE9]: `Grid de veículos com acesso rápido a informação do veículo (bateria, horário de última comunicação,
                  status do veículo, localização etc.).`,
  [k.CORPORATE10]: `Localização de veículo mais próximo de um ponto de interesse.`,
  [k.CORPORATE11]: `Lembrete de revisões programadas por Km ou odômetro.`,
  [k.CORPORATE12]: `Alertas em tempo real na plataforma e relatório das tratativas de alertas.`,
  [k.CORPORATE13]: `Alertas para o Condutor na cabine do veículo contemplando velocidade excessiva, freadas, acelerações e curvas bruscas,
                  ociosidade e falta de identificação do motorista.`,
  [k.CORPORATE14]: `Geração de relatórios com foco em veículos ou motoristas.`,
  [k.CORPORATE15]: `Relatórios com Rotas, Trajeto percorridos, Posições (Localização), Velocidade excessiva, Cercas eletrônicas,
                    Pontos de Interesse, Permanência em Pontos de Interesse.`,
  [k.CORPORATE16]: `Relatório consolidado com horário inicial, final e tempo em viagens, odômetro, tempo ligado, tempo em movimento,
                    tempo desligado, velocidade máxima e identificação do motorista.`,
  [k.CORPORATE17]: `Relatório de comportamento do motorista e erros de direção contemplando infrações como aceleração,
                    freadas e curvas bruscas e velocidade excessiva.`,
  [k.CORPORATE18]: ``,
  [k.CORPORATE18_1]: `  - Em equipamentos com CAN, relatórios incluindo dado do barramento CAN.`,
  [k.CORPORATE18_2]: `  - Em equipamentos com Vídeo-Telemetria, relatórios e alertas de riscos de
                      direção e ranking de risco de motoristas contemplando Fadiga, Distração, Sonolência,
                      Cigarro, Uso de celular, etc.`,
  [k.CORPORATE18_3]: `  - Em veículos comerciais, relatório de Jornada de Trabalho de Motoristas.`,
  [k.CORPORATE18_4]: `  - Em ônibus que seguem regulamentação ANTT, relatórios de viagens,
                      itinerários e passageiros.`,
  [k.CORPORATE19]: `Relatórios e Dashboards (Gráficos, Visuais) de Controle de Uso dos Ativos Móveis
                    contemplando uso em horário comercial, fora de horário comercial, fim de semanas e
                    feriados com ranking de distância percorrida por faixa de horário.`,
  [k.CORPORATE20]: `Relatórios e Dashboards (Gráficos, Visuais) de Gestão da Eficiência da
                    Frota contemplando Km Rodado, Eficiência de uso dos Ativos, ranking de
                    distância percorrida, atraso no início das atividades e fim prematuro das
                    atividades, ranking de inatividades da frota e ranking de veículos com
                    pouca utilização.`,
  [k.CORPORATE21]: `Relatórios e Dashboards (Gráficos, Visuais) com Utilização de Veículos Dentro e
                    Fora de Regiões definidas, com duração e km rodado dentro e fora de regiões pré-definidas.`,
  [k.CORPORATE22]: `Relatório e Dashboards (Gráfico, Visuais) com Ranking de Infrações Mensal por veículo, tipo
                    de infração e nota final da frota com visão evolutiva mensal.`,
  [k.CORPORATE23]: `Gestão de combustível e abastecimento com integração com plataformas de gestão de combustível.`,
  [k.CORPORATE24]: `Soluções para monitoramento de veículos elétricos (status de carga, percentual de carga
                    de veículos elétricos, consumo de energia (projetos específicos utilizando-se OBD –
                      Computadores de bordo).`,
  [k.RENTAL_CAR]: `Locadoras / Fleet Outsourcing (Empresas de terceirização de frotas)`,
  [k.RENTAL_TITLE]: `Parceiro de Locadoras e de Empresas de 'Car Sharing'.`,
  [k.RENTAL_HEAD]: `A trackIQ fornece uma ampla gama de serviços e soluções para segurança, mobilidade
                  e gestão de frotas para locadoras e empresas de terceirização de frotas.`,
  [k.RENTAL0]: `Para locadoras que ofertam serviços para clientes individuais, sejam eles pessoas físicas
                ou prestadores de serviço privado de transporte de passageiros entre outros, nossas soluções abrangem:`,
  [k.RENTAL1]: `Monitoramento, localização em tempo real e recuperação de veículos roubados ou furtados.`,
  [k.RENTAL2]: `Grid de veículos com acesso rápido a informação do veículo (bateria, horário de última comunicação,
                status do veículo, localização etc.).`,
  [k.RENTAL3]: `Mapas digitais com diferentes camadas (satelital, tráfego, etc.) com ferramentas rápidas de cadastro
                de pontos de interesse, cercas eletrônicas, etc.`,
  [k.RENTAL4]: `Lembrete de revisões programadas por Km ou odômetro.`,
  [k.RENTAL5]: `Solução de detecção de 'jamming' (bloqueador de sinal da rede celular) e detecção de reboque do veículo.`,
  [k.RENTAL6]: `Sistema de alerta de localização de veículos fora de região programada para rodagem.`,
  [k.RENTAL7]: `Soluções para medição de consumo de combustível por locação.`,
  [k.RENTAL8]: `Relatório de posicionamento, odômetro e velocidade.`,
  [k.RENTAL9]: ``,
  [k.RENTAL10]: `Para empresas de terceirização de frota, que atendem diferentes
                tipos de clientes corporativos, além das funcionalidades
                apresentadas acima, nossas soluções abrangem:`,
  [k.RENTAL11]: `Identificação de condutor por i-button ou RFID com possibilidade
                de bloqueio do veículo em caso de não identificação.`,
  [k.RENTAL12]: `Alertas ao motorista em caso de condução fora dos padrões definidos pela empresa.`,
  [k.RENTAL13]: `Alertas para o Condutor na cabine do veículo contemplando velocidade excessiva, freadas, acelerações e
                curvas bruscas, ociosidade e falta de identificação do motorista.`,
  [k.RENTAL14]: `Geração de relatórios com foco em veículos ou motoristas.`,
  [k.RENTAL15]: `Relatórios com Rotas, Trajeto percorridos, Posições (Localização), Velocidade excessiva,
                Cercas eletrônicas, Pontos de Interesse, Permanência em Pontos de Interesse.`,
  [k.RENTAL16]: `Relatório consolidado com horário inicial, final e tempo em viagens, odômetro, tempo ligado,
                tempo em movimento, tempo desligado, velocidade máxima e identificação do motorista.`,
  [k.RENTAL17]: `Relatório de comportamento do motorista e erros de direção contemplando infrações como
                aceleração, freadas e curvas bruscas e velocidade excessiva.`,
  [k.RENTAL18]: `Relatórios e Dashboards (Gráficos, Visuais) de Controle de Uso dos Ativos Móveis
                contemplando uso em horário comercial, fora de horário comercial, fim de semanas e
                feriados com ranking de distância percorrida por faixa de horário.`,
  [k.RENTAL19]: `Relatórios e Dashboards (Gráficos, Visuais) de Gestão da Eficiência da Frota
                contemplando Km Rodado, Eficiência de uso dos Ativos, ranking de distância percorrida,
                atraso no início das atividades e fim prematuro das atividades, ranking de inatividades da
                frota e ranking de veículos com pouca utilização.`,
  [k.RENTAL20]: `Notificação de acidentes.`,
  [k.RENT1]: `Relatórios de Gestão - BI`,
  [k.RENT2]: `Notificação de Acidentes`,
  [k.RENT3]: `Gerenciamento de Operações`,
  [k.RENT4]: `Telemetria`,
  [k.RENT5]: `Proteção do Patrimônio`,
  [k.RENT6]: `Gestão de Utilização de Veículos`,
  [k.SOLUTION1]: `Serviços Profissionais`,
  [k.SOLUTION2]: `Serviços Consultoria`,
  [k.SOLUTION3]: `Serviços Inteligência`,
};
export { portuguese };
