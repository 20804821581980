import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - What

// ============ React Dependencies ========

import React from 'react';

// ============ Image Imports =============

import what from '../assets/images/what_image_1.jpeg';
import what1 from '../assets/images/oems.jpg';
import focus from '../assets/images/focus.svg';

// ============ What Component ==========

export default function Connected() {
  return (
            <React.Fragment>
                <section id="connected_car">
                    <div className="container-wrap">
                        <br /><br /><br />
                        <p className="what-subheading">{i18n.t(k.CONNECTED_VEHICLES)}</p>
                        <img className="img-fluid what-image" src={what1} alt="Man in Office"></img>
                        <p className="work-subheading">{i18n.t(k.CONNECTED_TITLE)}</p>
                        <hr className="work-red-line"></hr>
                    <strong><p className="text-center lorem">{i18n.t(k.CONNECTED_HEADER)}</p></strong>
                        <div className="grid-what-container">
                            <div className="grid-what-item1">
                                <div>
                                    <img className="img-fluid card connected-tablets-image" src={what} alt="iPad" />
                                </div>
                                <div className="card connected-tablets ">
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT1)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT2)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT3)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT4)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT5)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT6)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT7)}</p>
                                    <p className="connected-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.WHAT8)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </section>
            </React.Fragment>);

    }


