// eslint-disable-next-line
import React, { useState, useContext } from 'react';

//import styles from '../App/utils/module.css';
import { makeStyles } from "@material-ui/core";
import { styled } from '@mui/material/styles';
// eslint-disable-next-line
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';

import { blue, blueGrey } from '@mui/material/colors';

import i18n from 'i18next';
import k from "./../../i18n/keys";

import logo from './../../assets/images/trackiq.png';
// import { UserContext } from '../contexts/UserContext';

//import * as generalService from '../services/generalService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    zIndex: 1,
};

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    appMain: {
        paddingLeft: '250px',
        width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    login_buttons: {
        display: 'block',
        margin: '5px 0  0 0',
        textAlign: 'center',
    },
    login_btn: {
        display: 'inlineBlock',
        margin: '0 10px 0 10px!important',
    },
    login_field: {
    display: 'flex',
    margin: '20px auto',
    marginTop: '40px',
    width: '100%',
    },
    login_header: {
    textAlign: 'center',
    color: '#ad8448',
    fontSize: '2rem',
    },
    login_message: {
    textAlign: 'center',
    color: '#80a8d0',
    fontSize: '1.5rem',
    paddingTop: '1rem',
    },
    login_forgot: {
        textAlign: 'left',
        color: '#ad8448',
        fontSize: '0.8rem',
        padding: '10px',
    },
    modal: {
        '&.css-1wnsr1i': {
            maxWidth: 645,
            position: 'absolute',
            top: '50%',
            margin: '0 auto',
            width: '400px',

        }
    }
}))


export default function ModalLogin(props) {

    const classes = useStyles();
    const {
        // eslint-disable-next-line
        open, setOpen,
        handleClose,
        handleInputChange,
        handleSubmit,
        errorEmail,
        errorPass,
        errorLogin,
        // eslint-disable-next-line
        username,
        // eslint-disable-next-line
        firstname,
        // eslint-disable-next-line
        lastname,
        email,
        password,
        display,
        handleForgot
    } = props
    //const { email, password } = useContext(UserContext);
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    }));

    const ColorButtonBG = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(blueGrey[100]),
        backgroundColor: blueGrey[100],
        '&:hover': {
            backgroundColor: blueGrey[500],
        },
    }));

    const handleEnter = (e) => {
        e.preventDefault();
    }


    return (

        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={classes.modal} >
                    <Card sx={{ maxWidth: 345, mx: 'auto' }}>
                        <CardMedia
                            sx={{ height: 100 }}
                            image={logo} alt="Customer Logo"
                            title="TrackIQ logo"
                        />
                    </Card>


                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <p className={classes.login_header}>{i18n.t(k.LOGIN)} </p>
                    </Typography>


                    {/* <UserContext.Consumer> */}


                    <>
                        <form className="contact-form-login" onSubmit={handleSubmit} name='submit'>

                            <TextField
                                className={classes.login_field}
                                fullWidth={true}
                                id="email-input_login"
                                name="email"
                                label="Email"
                                type="text"
                                value={email}
                                onChange={handleInputChange}
                                error={errorEmail}
                                sx={{ input: { color: '#80a8d0'}, mt: 2, mb: 2, zIndex: 0 }}
                                autoComplete="new-password"
                            />

                            <TextField
                                className={classes.login_field}
                                fullWidth={true}
                                id="password-input_login"
                                name="password"
                                label={i18n.t(k.PASSWORD)}
                                type="password"
                                value={password}
                                onChange={handleInputChange}
                                error={errorPass}
                                helperText={password.length < 5 ? i18n.t(k.PASS_MESSAGE)  : ""}
                                sx={{ input: { color: '#80a8d0' } }}
                                autoComplete="new-password"
                                onPointerEnter={handleEnter}
                            />
                            <Typography id="modal-modal-forgot" variant="body2" component="div">
                                <div className={classes.login_forgot} onClick={handleForgot}>{i18n.t(k.FORGOT)} </div>
                            </Typography>
                            <div className={classes.login_buttons}>
                                <ColorButtonBG variant="contained" className={classes.login_btn} onClick={(event) => handleClose(event, 'login')}>{i18n.t(k.CANCEL)}</ColorButtonBG>
                                <ColorButton variant="contained" type="submit" disabled={errorLogin} className={classes.login_btn} onClick={handleSubmit}>{i18n.t(k.SUBMIT)}</ColorButton>
                            </div>
                        </form>
                    </>
                    <Box sx={{
                        display: { display }, position: 'absolute', top: '50%', left: '50%'
                    }}>
                        <CircularProgress sx={{ color: '#51708e', width: '60px!important', height: '60px!important', zIndex: '5' }} />
                    </Box>
                </Box>

            </Modal>
        </>

    )
}
