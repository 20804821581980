import i18n from 'i18next';
import k from "./../i18n/keys";

// React Component - About
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@material-ui/core/Typography";

// ============ React Dependencies ========

import React from 'react';
import { FooterContainer } from '../containers/footer';

// ============ Image Imports =============

import about from '../assets/images/vision.jpg';

// ============ About Component ==========

export default function Vision() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '90px',
                        //   position: 'absolute',
                        //   top: '5.5rem',
                        width: '100%',
                        height: 'auto',
                    },
                }}
            >
                <Paper elevation={3} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.TRACKIQ)}</Typography>
                    <Typography variant="h5">{i18n.t(k.VISION)}</Typography>
                </Paper>

            </Box>
            <section id="about">
                <div className="row">
                    <div className="about" >
                        <div className="topic-header-about">
                            <br />

                            <img className="img-fluid design-image" src={about} alt="About us" />
                            <strong><p className="text-center lorem">{i18n.t(k.VISION)}</p></strong>
                            <strong><p className="text-center lorem">{i18n.t(k.VISION_HEAD)}</p></strong>

                        </div>



                    </div>
                </div>

                <FooterContainer className="footer--pin" />
            </section>
        </React.Fragment>);

}