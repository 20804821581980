import React, {  } from 'react';

//import styles from './page.module.css';
import { makeStyles } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { blue, blueGrey } from '@mui/material/colors';

import i18n from 'i18next';
import k from "./../../i18n/keys";

import logo from './../../assets/images/trackiq.png';
//import * as generalService from '../services/generalService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    appMain: {
        paddingLeft: '250px',
        width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    login_buttons: {
        display: 'block',
        margin: '5px 0  0 0',
        textAlign: 'center',
    },
    login_btn: {
        display: 'inlineBlock',
        margin: '0 10px 0 10px!important',
    },
    login_field: {
        display: 'flex',
        margin: '10px auto',
        marginTop: '40px',
        width: '100%',
    },
    login_header: {
        textAlign: 'center',
        color: '#ad8448',
        fontSize: '2rem',
    },
    login_message: {
        textAlign: 'center',
        color: '#80a8d0',
        fontSize: '1.5rem',
        paddingTop: '1rem',
    },
    modal: {
        '&.css-1wnsr1i': {
            maxWidth: 645,
            position: 'absolute',
            top: '50%',
            margin: '0 auto',
            width: '400px',

        }
    }
}))

export default function ModalMessage(props) {

    const classes = useStyles();
    // eslint-disable-next-line
    const { open, setOpen, handleClose, handleSubmit} = props

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    }));

    const ColorButtonBG = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(blueGrey[100]),
        backgroundColor: blueGrey[100],
        '&:hover': {
            backgroundColor: blueGrey[500],
        },
    }));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={classes.modal}>
                <Card sx={{ maxWidth: 345, mx: 'auto' }}>
                    <CardMedia
                        sx={{ height: 100 }}
                        image={logo} alt="Customer Logo"
                        title="Card Media"
                    />
                </Card>
                <Typography id="modal-modal-title" variant="h6" component="h6">
                    <p className={classes.login_message}>{i18n.t(k.LOGOUT)} </p>
                </Typography>
                <div className={classes.login_buttons}>
                    <ColorButtonBG variant="contained" className={classes.login_btn} onClick={(event) => handleClose(event, 'logout')}>{i18n.t(k.CANCEL)}</ColorButtonBG>
                    <ColorButton variant="contained" className={classes.login_btn} onClick={(event) => handleClose(event, 'sair')}>{i18n.t(k.EXIT)}</ColorButton>
                </div>
            </Box>
        </Modal>

    )
}