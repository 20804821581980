import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - Artificial Intelligence

// ============ React Dependencies ========

import React from 'react';

// ============ Image Imports =============

import ai_header from '../assets/images/ia_image_header1.jpg';
import ai4 from '../assets/images/ia_image_5.jpg';
import focus from '../assets/images/focus.svg';


// ============ About Component ==========


export default function ArtificialIntelligence() {
  return (
            <React.Fragment>
                <section id="artificial_intelligence">
                    <div className="container-wrap">
                        <div className="about">
                            <br /><br /><br />
                            <div className="topic-header">
                                <p className="what-subheading">{i18n.t(k.TECHNOLOGY)}</p>
                                <img src={ai_header} style={{ width: '50%' }} alt="Artificial Intelligence" />
                                <p className="what-subheading">{i18n.t(k.ARTIFICIAL_INTELLIGENCE)}</p>
                            </div>
                            <hr className="about-red-line"></hr>

                            <p className="text-center lorem"><strong>
                                {i18n.t(k.SOFTWARE_HARDWARE_AND_SERVIC)}
                            </strong>
                            </p>
                            <div className="grid-what-container">
                                <div className="grid-what-item1">
                                    <div>
                                        <img className="img-fluid card ai-tablets-image" src={ai4} alt="iPad" />
                                    </div>
                                    <div className="card ai-tablets ">
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI1)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI2)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI3)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI4)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI5)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI6)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI7)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI8)}</p>
                                        <p className="ai-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.AI9)}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr />
                </section>
            </React.Fragment>);

    }