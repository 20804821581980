import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - About
// Installed - npm install emailjs-com --save
// ============ React Dependencies ========

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
//import ReactGA from 'react-ga';
// ============ Image Imports =============


import contact from '../assets/images/contact_image_2.jpg';
import office_image from '../assets/images/contact_image_2.png';

// ============ About Component ==========

export default function Contact() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const [state, setState] = useState({
        name: "",
        lastname: "",
        email: '',
        title: '',
        company: '',
        phone: '',
        reason: '',
        message: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        var template_params = {
            title: state.title,
            name: state.name,
            lastname: state.lastname,
            company: state.company,
            phone: state.phone,
            from_name: state.email,
            to_name: 'trackIQ Support Team',
            subject: 'Visit trackIQ Site',
            reason: state.reason,
            message_html: state.message,
        }

        var service_id = "contact_service";
        var template_id = "trackIQ_001";
        var user_id = '2FZ9hkxvRt-6Y4sVG'; //user_7kPkOhEo0c6N7DHtmJ9o7'


        if ((state.name === '') || (state.lastname === '') || (state.email === '')) {
            document.getElementById('message-error').innerHTML = "Please enter mandatory data!";
        } else {
            emailjs.send(service_id, template_id, template_params, user_id)
                .then((result) => {
                    console.log(result.text);
                    resetForm();
                }, (error) => {
                    console.log(error.text);
                });
        }
    }

    const handleChange = ( name, event ) => {

        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }


    const resetForm = () => {
        setState({ name: '', lastname: '', title: '', company: '', email: '', phone: '', reason: '', message: '' })
    }


    return (
        <React.Fragment>
            <section id="contact">
                <div className="container-wrap">
                    <br /><br /><br />
                    <div className='contact-header'>{i18n.t(k.CONTACT_US1)}</div>
                    <img className="contact-image img-fluid" src={contact} alt="CONTACT_US1" />
                </div>

                <div id="form">
                    <div className="grid-contact-container">
                        <form id="waterform" method="POST" onSubmit={handleSubmit}>

                            <div className="grid-contact-container">
                                <div className="grid-contact-item1">
                                    <div>
                                        <div className="" id="name-form">
                                            <label className="contact-label" htmlFor="name">{i18n.t(k.YOUR_NAME)}</label>
                                            <input className="contact-input" type="text" id="name" value={state.name} name="name" onChange={(event) => handleChange('name', event)} />
                                        </div>

                                        <div className="" id="title-form">
                                            <label className="contact-label" htmlFor="title">{i18n.t(k.YOUR_TITLE)}</label>
                                            <input className="contact-input" type="text" id="title" value={state.title} name="title" onChange={(event) => handleChange('title', event)} />
                                        </div>

                                        <div className="" id="email-form">
                                            <label className="contact-label" htmlFor="email">{i18n.t(k.YOUR_E_MAIL)}</label>
                                            <input className="contact-input" type="email" id="email" value={state.email} name="email" onChange={(event) => handleChange('email', event)} />
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className="" id="lastname-form">
                                        <label className="contact-label" htmlFor="lastname">{i18n.t(k.YOUR_LASTNAME)}</label>
                                        <input className="contact-input" type="text" id="lastname" value={state.lastname} name="lastname" onChange={(event) => handleChange('lastname', event)} />
                                    </div>
                                    <div className="" id="company-form">
                                        <label className="contact-label" htmlFor="company">{i18n.t(k.YOUR_COMPANY)}</label>
                                        <input className="contact-input" type="text" id="company" value={state.company} name="company" onChange={(event) => handleChange('company', event)} />
                                    </div>
                                    <div className="" id="phone-form">
                                        <label className="contact-label" htmlFor="phone">{i18n.t(k.YOUR_PHONE)}</label>
                                        <input className="contact-input" type="text" id="phone" value={state.phone} name="phone" onChange={(event) => handleChange('phone', event)} />
                                    </div>
                                </div>
                            </div>

                            <div className="grid-contact-container-submit">
                                <div className="form-group">
                                    <label className="contact-label" htmlFor="sel1">{i18n.t(k.REASON_FOR_YOUR_CONTACT)}</label>
                                    <select className="form-control contact-select" id="sel1" value={state.reason} name="reason" onChange={(event) => handleChange('reason', event)}>
                                        <option className="contact-input" active="true">{i18n.t(k.SELECT_ONE)}</option>
                                        <option className="contact-input">{i18n.t(k.PROFESSIONAL)}</option>
                                        <option className="contact-input">{i18n.t(k.CONSULTING)}</option>
                                        <option className="contact-input">{i18n.t(k.INTELLIGENCE)}</option>
                                        <option className="contact-input">{i18n.t(k.CORPORATE_FLEET)}</option>
                                        <option className="contact-input">{i18n.t(k.CONNECTED_VEHICLES)}</option>
                                        <option className="contact-input">{i18n.t(k.MOBILITY_SERVICES)}</option>
                                        <option className="contact-input">{i18n.t(k.DESIGN)}</option>
                                        <option className="contact-input">{i18n.t(k.TECHNOLOGY)}</option>
                                        <option className="contact-input">{i18n.t(k.CUSTOMIZATIONS_TITLE)}</option>
                                        <option className="contact-input">{i18n.t(k.TELEMATICS)}</option>
                                        <option className="contact-input">{i18n.t(k.OTHERS)}</option>
                                    </select>
                                    <label className="contact-label" htmlFor="message">{i18n.t(k.YOUR_MESSAGE)}</label>
                                    <textarea className="contact-textarea" id="message" value={state.message} name="message" onChange={(event) => handleChange('message', event)}></textarea>
                                    <input className="contact-submit" type="submit" value={i18n.t(k.SEND_MESSAGE)} />
                                    <label id="message-error" className="message-error" type="text" />
                                </div>
                            </div>
                        </form>


                        <div className="card contact-card contact-grid" style={{ width: '35rem', height: '50rem' }}>
                            <img className="card-img-top" src={office_image} alt="Company address" />
                            <div className="card-body">
                                <div className="contact-text">
                                    <p className='contact-header'>{i18n.t(k.COMPANY_NAME)}</p>
                                    <p className='contact-header'>{i18n.t(k.COMPANY_ADDRESS)}</p>
                                    <p className='contact-header'>{i18n.t(k.CITY)}</p>
                                    <p className='contact-header'>{i18n.t(k.STATE)}</p>
                                    <p className='contact-header'>{i18n.t(k.ZIP)}</p>
                                    <br />
                                    <p className='contact-header'>{i18n.t(k.PHONE)}</p>
                                    <p className='contact-header'>{i18n.t(k.WHATS)}</p>
                                    <p className='contact-header'>{i18n.t(k.EMAIL)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </React.Fragment>);

    }
