import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - Artificial Intelligence

// ============ React Dependencies ========

import React from 'react';

// ============ Image Imports =============
//
import rental from '../assets/images/rental_car.jpg';
import rental2 from '../assets/images/TCUs.jpg';
import rental1 from '../assets/images/rental_car1.jpeg';
import rental3 from '../assets/images/private_fleet.jpg';
//import pconnect from '../assets/Pconnect_logo.svg';
import pconnect2 from '../assets/images/trackiq_connect.jpg';

import focus from '../assets/images/focus.svg';


// ============ About Component ==========

export default function RentalVehicles() {
    return (
        <React.Fragment>
            <section id="corporate">
                <div className="container-wrap">
                    <br /><br /><br />
                    <div className="about">
                        <div className="topic-header">
                            <p className="what-subheading">{i18n.t(k.RENTAL_CAR)}</p>
                            <img src={rental} style={{ width: '50%' }} alt="Corporate Fleet" />
                            <p className="what-subheading">{i18n.t(k.RENTAL_TITLE)}</p>
                        </div>
                        <hr className="about-red-line"></hr>

                        <p className="text-center lorem"><strong>
                            {i18n.t(k.RENTAL_HEAD)}
                        </strong>
                        </p>
                        <p className="text-center lorem"><strong>
                            {i18n.t(k.RENTAL0)}
                        </strong>
                        </p>
                        {/* <img src={pconnect2} className="corporate-pconnect" style={{ width: '8%' }} alt="Corporate Fleet" /> */}
                        <div className="grid-what-container">
                            <div className="grid-what-item1">
                                <div>
                                    <img className="img-fluid card corporate-tablets-image" src={rental1} alt="iPad" />
                                    {/* <img className="img-fluid card corporate-tablets-image" src={rental2} alt="iPad" /> */}
                                </div>
                                <div className="card corporate-tablets ">
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL1)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL2)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL3)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL4)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL5)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL6)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL7)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL8)}</p>
                                    {/* <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL9)}</p> */}
                                </div>
                            </div>
                        </div>

                        {/* <p className="text-center lorem"><strong>
                            {i18n.t(k.RENTAL_HEAD)}
                        </strong>
                        </p> */}
                        <p className="text-center lorem"><strong>
                            {i18n.t(k.RENTAL10)}
                        </strong>
                        </p>
                        {/* <img src={pconnect2} className="corporate-pconnect" style={{ width: '8%' }} alt="Corporate Fleet" /> */}
                        <div className="grid-what-container">
                            <div className="grid-what-item1">
                                <div>
                                    <img className="img-fluid card corporate-tablets-image" src={rental3} alt="iPad" />
                                    <img className="img-fluid card corporate-tablets-image" src={rental2} alt="iPad" />
                                </div>
                                <div className="card corporate-tablets ">

                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL11)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL12)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL13)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL14)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL15)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL16)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL17)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL18)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL19)}</p>
                                    <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL20)}</p>
                                    {/* <p className="corporate-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.RENTAL9)}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </section>
        </React.Fragment>);

}

