import React from 'react';
//import { makeStyles } from "@material-ui/core";
//import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import k from "./../i18n/keys";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@material-ui/core/Typography";
// import Carousel from 'react-material-ui-carousel'
// import logo from './../assets/images/what_image_2.jpg';

import Contact from './../components/Contact';

import { FooterContainer } from '../containers/footer';

// const useStyles = makeStyles(theme => ({
//     appMain: {
//         // paddingLeft: '250px',
//         // width: '100%'
//     },
//     root: {
//         backgroundColor: '#fafafa',
//     },
//     btn: {
//         padding: theme.spacing(1)
//     },
//     menu: {
//         display: 'flex',
//         top: '60px',
//         position: 'fixed',
//         width: '80%',
//         zIndex: 2,
//         backgroundColor: '#fafafa',
//     },
//     splash: {
//         width: '60%',
//         height: 'auto',
//         // position: 'absolute',
//         // top: '13rem',
//         // left: '20%',
//         opacity: '0.7',
//         margin: '20px auto 0 auto',
//         '@media(max-width: 700px)': {
//             width: '90%',
//             left: '5%',
//         }
//     },
//     splashImage: {
//         width: '60%',
//         height: 'auto',
//         // position: 'absolute',
//         // top: '13rem',
//         // left: '20%',
//         opacity: '0.7',
//         maxWidth: '100%',
//         '@media(max-width: 700px)': {
//             width: '90%',
//             left: '5%',
//         }
//     },
//     headline: {
//         textAlign: 'center',
//         padding: '5px',
//         // marginTop: '47vw',
//         fontSize: '2rem',
//         width: '100%',
//         '@media(max-width: 820px)': {
//             fontSize: '1.8rem',
//             // marginTop: '60vw',
//         },
//         '@media(max-width: 420px)': {
//             fontSize: '1.5rem',
//             // marginTop: '105vw',
//         },
//     },
//     headline1: {
//         color: '#5f7383',
//         fontSize: '30px',
//         // position: 'absolute',
//         // left: '6%',
//         // top: '14rem',
//         '@media(max-width: 700px)': {
//             // fontSize: '30px',
//             // top: '8rem',
//             // left: '3%',
//             color: '#fafafa',
//         }
//     },
//     imgFluid: {
//         maxWidth: '100%',
//         height: 'auto',
//     }
// }))

export default function ContactUs() {
    // const { t } = useTranslation();
    // const classes = useStyles();

    // function Item(props) {
    //     return (
    //         <Paper elevation={0} className="carousel-home-title" sx={{ color: '#6ca4b5', fontWeight: 900 }}>
    //             <span id="carousel-id">{props.item.name}</span>
    //         </Paper>
    //     )
    // }

    // var items = [
    //     {
    //         name: i18n.t(k.OEM1),
    //         description: "OEM 1",
    //         alt: "OEM 1"
    //     },
    //     {
    //         name: i18n.t(k.OEM2),
    //         description: "OEM 2",
    //         alt: "OEM 2"
    //     },
    //     {
    //         name: i18n.t(k.OEM3),
    //         description: "OEM 3",
    //         alt: "OEM 3"
    //     },
    //     {
    //         name: i18n.t(k.OEM4),
    //         description: "OEM 4",
    //         alt: "OEM 4"
    //     },
    //     {
    //         name: i18n.t(k.OEM5),
    //         description: "OEM 5",
    //         alt: "OEM 5"
    //     },
    //     {
    //         name: i18n.t(k.OEM6),
    //         description: "OEM  6",
    //         alt: "OEM 6"
    //     },
    //     {
    //         name: i18n.t(k.OEM7),
    //         description: "OEM  7",
    //         alt: "OEM 7"
    //     }
    // ]
    return (

        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '90px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        width: '100%',
                        height: 'auto',
                    },
                }}
            >
                <Paper elevation={3} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.TRACKIQ)}</Typography>
                    <Typography variant="h5">{i18n.t(k.CONTACT_US)}</Typography>
                </Paper>

            </Box>
            <div className="content-container">
                {/* <Box className={classes.headline}
                >
                    <img className={`${classes.splash} ${classes.imgFluid}`} src={logo} alt="splash site trackiq" />
                    <Carousel
                        indicators={false}
                        animation='fade'
                        fullHeightHover={false}
                    >
                        {
                            items.map((item, i) => <Item key={i} item={item} />)
                        }
                    </Carousel>


                </Box> */}
                <Contact />
                <FooterContainer className="footer--pin" />
            </div>

        </div>
    )
}
