import i18n from 'i18next'; import k from "./../i18n/keys"; // React Component - Work

// ============ React Dependencies ======

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// ============ Image Imports =============

import professional1 from '../assets/images/professional_image_2.jpg';
import professional from '../assets/images/professional_image_1.jpg';
import focus from '../assets/images/focus.svg';
// ============ Work Component ==========

export default function Professional() {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const params = useParams();
    useEffect(() => {
    if (params && params.id === 'professional') {
        const element = document.getElementById(params.id);
        console.log('Professional', element, element.offsetTop)
        if ( element ) {
            console.log('Professional', element)
            const pos = element.offsetTop;
            window.scrollTo({top: pos, left: 0, behavior: 'smooth'});
        }
    }
    // eslint-disable-next-line
    }, [])

  return (
            <React.Fragment>
          <section id="professional">
                    <div className="container-wrap">
                        <br /><br /><br />
                        <p className="work-subheading">{i18n.t(k.PROFESSIONAL)}</p>
                        <img className="img-fluid work-image" src={professional} alt="Consulting Services"></img>
                        <p className="work-subheading" >{i18n.t(k.PROFESSIONAL_TITLE)}</p>
                        <hr className="work-red-line"></hr>
                        <div className="row work-text">
                            <strong><p className="text-center lorem">{i18n.t(k.PROFESSIONAL_HEAD)}</p></strong>
                        </div>
                    </div>

                    <div className="grid-what-container" >
                        <div className="grid-what-item1" >
                            <div>
                                <img className="img-fluid card professional-tablets-image" src={professional1} alt="iPad" />
                            </div>
                            <div  className="card professional-tablets ">
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL1)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL2)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL3)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL4)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL5)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL6)}</p>
                                <p className="professional-textarea"><img className="img-fluid focus-icon" src={focus} alt="Target" />{i18n.t(k.PROFESSIONAL7)}</p>
                            </div>
                        </div>
                    </div>

                </section>
            </React.Fragment>);

    }
