const k = require('./keys');

const english = {
  [k.TRACKIQ]: `trackIQ`,
  [k.ACCOUNT]: `Account`,
  [k.LOGIN]: `System Login`,
  [k.HOME]: `Start`,
  [k.ERROR_LOGIN]: `Identification Error at Login!`,
  [k.FORGOT]: `Forgot Password?`,
  [k.CHANGE_PASS]: `Change Password`,
  [k.PASSWORD]: `Password`,
  [k.RESET_PASSWORD]: `Reset Password`,
  [k.CONFIRMATION]: `Confirmation`,
  [k.CONFIRMATION_HELPER]: `Confirmation must match password!`,
  [k.LOG]: `Login`,
  [k.EXIT]: `Exit`,
  [k.LOGOUT]: `Do you want to leave the system?`,
  [k.CANCEL]: `Cancel`,
  [k.SUBMIT]: `Submit`,
  [k.PARTNER]: `Your Tecnology Partner!`,
  [k.OEM]: `OEM`,
  [k.FLEET]: `Fleet`,
  [k.FLEET1]: `Intelligent Fleet Management`,
  [k.FLEET2]: `Secure Scalability`,
  [k.FLEET3]: `Management Reports - BI`,
  [k.FLEET4]: `Fuel Management`,
  [k.FLEET5]: `Fleet Usage Management`,
  [k.RENTAL]: `Rental`,
  [k.FSERVICE]: `Field Service`,
  [k.SOLUTIONS]: `Solutions`,
  [k.SYSTEM]: `System`,
  [k.COOKIE]: `Cookies`,
  [k.COOKIES]: `We use cookies to improve your experience on our website. By continuing,
                you agree to this use. You can set your browser to block cookies or to be
                informed when they are sent. Find out more.`,
  [k.ACCEPT]: `Accept`,
  [k.DECLINE]: `Decline`,
  [k.COPYRIGHT]: `© 2023 trackIQ, All rights reserved.`,
  [k.WORK]: `Work with us`,
  [k.ABOUT]: `About`,
  [k.ABOUTUS]: `About Us`,
  [k.STORY]: `Story`,
  [k.VISION]: `Vision`,
  [k.VISION_HEAD]: `To be the best choice in automotive telematics services and technologies as
                    perceived by customers and partners in our ecosystem.`,
  [k.MISSION]: `Mission`,
  [k.MISSION_HEAD]: `To be a protagonist in the development of automotive telematics solutions:`,
  [k.MISSION_HEAD1]: `• Delivering the best experience and the best results to our customers;`,
  [k.MISSION_HEAD2]: `• Always innovating and improving through the continuous use of knowledge and technology;`,
  [k.MISSION_HEAD3]: `• Having a positive impact on the sustainability and excellence of our customers, employees,
                      partners and society as a whole.`,
  [k.SERVICES]: `Services`,
  [k.PROFESSIONAL]: 'Professional',
  [k.CONSULTING]: 'Consulting',
  [k.INTELLIGENCE]: 'Intelligence',
  [k.ELECTRICALS]: 'Veículos Elétricos',
  [k.CORPORATE_FLEET]: 'Corporate Fleet',
  [k.CONTACT]: `Contact`,
  [k.CONTACT_US]: `Contact Us`,
  [k.CONTACT_US1]: `Contact us`,
  [k.YOUR_NAME]: `Name *`,
  [k.YOUR_LASTNAME]: `Lastname *`,
  [k.YOUR_TITLE]: `Title`,
  [k.YOUR_COMPANY]: `Company`,
  [k.YOUR_E_MAIL]: `Your e-mail *`,
  [k.YOUR_PHONE]: `Phone`,
  [k.REASON_FOR_YOUR_CONTACT]: 'Reason for your contact',
  [k.YOUR_MESSAGE]: `Your message`,
  [k.OTHERS]: `Others`,
  [k.SEND_MESSAGE]: 'Send your message',
  [k.COMPANY_NAME]: 'trackIQ - Technology Partner',
  [k.COMPANY_ADDRESS]: 'Avenida Omega, 219 - Cj 34',
  [k.CITY]: 'Barueri',
  [k.STATE]: 'São Paulo',
  [k.COUNTRY]: 'Brasil',
  [k.ZIP]: '06472-005',
  [k.PHONE]: 'Phone:    +55 11-4181-2267',
  [k.WHATS]: 'Whatsapp: +55 11-98133-8108',
  [k.FOLLOW_US]: 'Follow Us',
  [k.EMAIL]: 'ascalmon@trackiq.com.br',
  [k.POLICIES]: 'Policies',
  [k.PRIVACY_POLICY]: 'Privacy',
  [k.COOKIES_POLICY]: 'Cookies',
  [k.TERMS_OF_SERVICE]: 'Terms of Service',
  [k.LEARN_MORE]: `LEARN MORE`,
  [k.OEM1]: `Connectivity`,
  [k.OEM2]: `Mobility Services`,
  [k.OEM3]: `Unique Design - Apps`,
  [k.OEM4]: `Telematics`,
  [k.OEM5]: `Technology - Machine Learning - ML`,
  [k.OEM6]: `Customizations`,
  [k.OEM7]: `Technology - Artificial Intelligence - AI`,
  [k.OEM8]: `Electric Vehicles`,
  [k.NEXT]: `Next`,
  [k.PREVIOUS]: `Previous`,
  [k.CONNECTIVITY]: `Connectivity`,
  [k.WHAT_WE_DO]: `What We Do`,
  [k.SOME_OF_OUR_WORK]: `SOME OF OUR WORK`,
  [k.STORY_ABOUT_US]: `STORY ABOUT US`,
  [k.ABOUT_PART_1_0]: `The `,
  [k.ABOUT_PART_1]: ` was created to provide services and solutions for Mobility and Vehicle Telematics
                    to corporate clients such as automotive OEMs, large fleet operators and rental companies.`,
  [k.ABOUT_PART_2_00]: `The extensive experience of its managers and consultants simplifies the delivery of the best experience
                      and the best results in terms of differentiated service and technology to our clients.`,
  [k.ABOUT_PART_2]: ` we are driven by continuous improvement, generating new knowledge, practices, algorithms
                     and modeling that contribute to disruptive progress and innovation in the automotive ecosystem,
                     by applying cloud technologies with scalability, security and high availability.`,
  [k.ABOUT_PART_2_0]: `At `,
  [k.ABOUT_PART_3]: `Our management model using technologies for mining, analyzing, compiling data and
                    integration of different partners in the automotive ecosystem summarizes, in a simplified way,
                    the main indicators of mobile asset management, a basic premise for our clients to make evidence-based
                    decisions in an unambiguous and careful manner.`,
  [k.ABOUT_PART_4_0]: `At `,
  [k.ABOUT_PART_4]: ` we operate in the areas of M2M (Machine-to-Machine Connectivity) and IoT (Internet of Things) oriented towards the
                    automotive segment, using solutions embedded in vehicles for tracking, data monitoring
                    (vehicle telemetry), image monitoring (video telemetry) and vehicle diagnosis
                    (artificial intelligence).`,
  [k.ABOUT_PART_5]: `Our solutions help automakers digitize their services, connectivity and
                    and interaction with their customers, in reducing warranty costs for their vehicles and in monetizing
                    of data from their vehicles, building customer loyalty and connecting them to their brands.`,
  [k.ABOUT_PART_6]: `We value an agile, collaborative, transparent and ethical environment with our clients, collaborators
                     and partners, working and dialoguing to always do better.`,
  [k.ABOUT_PART_7]: `We want to be the best choice for automotive telematics as perceived by our customers, acting
                    as a protagonist in the development of innovative solutions and services for the automotive segment of
                    connected vehicles.`,
  [k.ABOUT_PART_8]: ``,
  [k.ABOUT_PART_9]: ``,
  [k.COMPANY_VALUES_HEAD]: ``,
  [k.COMPANY_VALUES1]: `• EXCELLENCE:
                        o We always do better, every day, to continuously deliver our customers the best result and the best experience.`,
  [k.COMPANY_VALUES2]: `• AGILITY:
                        o We listen to and understand our clients, acting quickly and objectively to meet their needs.`,
  [k.COMPANY_VALUES3]: `• RESPECT:
                        o We are always empathetic and collaborative, working, learning and teaching with each other in an open and Ethical Way.`,
  [k.COMPANY_VALUES4]: `• INNOVATION:
                        o We continuously improve our technology and processes, generating disruptive and incremental advances for the automotive ecosystem.`,
  [k.COMPANY_VALUES5]: `• RESPONSIBILITY:
                        o We take care to create and maintain solid and lasting partnerships with each other, with our customers, partners and with our community.`,
  [k.IT_IS_OUR_COMMITMENT_AND_MARKET]: `It’s our commitment and market-focused approach that allows us
                   to help our customers reduce costs, protect assets, gain
                   efficiencies, improve resource utilization, minimize fraud
                   and most importantly: save lives by preventing accidents.`,
  [k.PASS_MESSAGE]: `Password must contain at least 5 characters`,
  [k.TELEMATICS]: `Telematics`,
  [k.TELEMATICS_TITLE]: `The power of our end-to-end solution`,
  [k.TELEMATICS_HEADER]: `The trackIQ cloud based platform is called TrackCloud
                  - Intelligent Cloud Based SW Platform that normalizes
                  different  sources of raw data into a well-structured information
                  center, generating insights and formatted information to feed OEMs
                  of the automotive industry, Dealer´s  network, OEM´ customers
                  and key business partners as insurance companies,  financial
                  groups and other relevant automotive players.`,
  [k.TELEMATICS1]: `Flexible architecture ready to integrate with OEM´s Manufacturing,
                  Marketing, Sales, Planning and other specific applications.`,
  [k.TELEMATICS2]: `Powerful tool to enable OEMs to customize and develop unique
                  features, implemented smoothly, in phases, unlocking hidden
                  data information in the new Digital Automotive Environment.
                  System flexibility allows development from Fleet & Mobility
                  Service Applications, customized to your needs, to
                  Machine Learning and Artificial Intelligence.`,
  [k.TELEMATICS3]: `Manages secure links to professional databases, created specifically
                  to store OEM´s customer data in a secure environment guaranteeing
                  privacy of the user´s data.`,
  [k.TELEMATICS4]: `Business improvements by harmonizing, structuring and correlating
                  vehicle raw data into relevant information, your entire business
                  can be enhanced, enabling significant improvements in a new vehicle launching`,
  [k.TELEMATICS5]: `Build your own App in a customized and scalable way to generate new revenue business
                  opportunities for data monetization and digital service implementation.`,
  [k.TELEMATICS6]: `Full integration with vehicle's electronic modules through devices that
                  sense changes in the vehicle behavior and feed the KA Connect with
                  information for the driver and the OEMs.`,
  [k.TELEMATICS7]: ``,
  [k.TELEMATICS8]: ``,
  [k.TCU1]: `High performance quad core processor.`,
  [k.TCU2]: `Bluetooth 4.1 (Low Energy).`,
  [k.TCU3]: `USB, Ethernet,WiFi (802.11 a/b/g/n) 2.4 GHz with Hotspot.`,
  [k.TCU4]: `Scalable NAD 4G Cat12 to 5G`,
  [k.TCU5]: `Scalable eMMC - 512MB to 1GB and RAM - 4GB to 16GB`,
  [k.TCU6]: `Embedded LINUX Operating System `,
  [k.TCU7]: `Options to DSRC.`,
  [k.TCU8]: `Options to Ethernet switch and C-V2X`,
  [k.DONGLES1]: `High performance quad core Qualcomm processor.`,
  [k.DONGLES2]: `Bluetooth 4.1 (Low Energy), 4G/LTE.`,
  [k.DONGLES3]: `USB, Ethernet,WiFi (802.11 a/b/g/n) 2.4 GHz with Hotspot.`,
  [k.DONGLES4]: `GPS, A-GPS, Accelerometer/Gyroscope.`,
  [k.DONGLES5]: `OBD-II or CAN interface, Full suite of OBD-II protocols, Low power consumption.`,
  [k.DONGLES6]: `Battery back-up.`,
  [k.DONGLES7]: `CAN security, Certifications: FCC, PTCRB`,
  [k.DONGLES8]: `Secure Over-the-Air updates, Mobile application for consumer`,
  [k.CUSTOMIZATIONS_TITLE]: 'Customizations',
  [k.CUSTOMIZATIONS_HEAD]: `The new Era of Connected Vehicles and Services is
                          transforming customer behavior in an unprecedent
                          speed. The way you communicate and interact with
                          you customers makes all difference to continue being
                          relevant in the future. Our Mobility & Customized
                          Services helps you to build your own App
                          (Mobile Applications) in a customized and scalable
                          way to generate new revenue business opportunities for
                          data monetization and digital service implementation.`,
  [k.CUSTOMIZATIONS1]: `Some services that can be generated to your customers:`,
  [k.CUSTOMIZATIONS2]: `Vehicle Health: Daily vehicle checklist. Your customer
                      know vehicle is safety for next trip.`,
  [k.CUSTOMIZATIONS3]: `Curfew: Get alerts if your vehicle starts moving in
                      forbidden hours independent where you are.`,
  [k.CUSTOMIZATIONS4]: `Driver Behavior: Enhance yearly customer insurance policies
                      price. Help your customers and families to have safety in
                      their heart. Provide them last trip drive behavior and
                      smart drive feedback.`,
  [k.CUSTOMIZATIONS5]: `Estimated Time of Arrival: Calculate your arrival time and
                      share with your parents or friends using best routing system.`,
  [k.CUSTOMIZATIONS6]: `Preventive Maintenance:  Maintenance Remind. Digital
                      Vehicle Maintenance Schedule with the Dealers.`,
  [k.CUSTOMIZATIONS7]: `Anchor: Protect your vehicle! Get real time alerts if your
                      TITLE  start moving. Your insurance know you care about your
                      assets. `,
  [k.CUSTOMIZATIONS8]: `Group Interaction: Share defined notification to your family
                      or friend group about you and your car during your trips. `,
  [k.CUSTOMIZATIONS9]: `Internet & Entertainment: Be updated! Extend Customer
                      connectivity experience from the house and office to the car.
                      Offer them high speed internet (Wi-Fi, BT, LTE, 5G)
                      embedded in the vehicle. `,
  [k.CUSTOMIZATIONS10]: `Recall Management: Save time and resources in Recall
                      advertising by direct communication with your relevant
                      vehicle owners in case of Recalls.`,
  [k.CUSTOMIZATIONS11]: `Keyless backup: Open your car with your App with security
                      code in case you lose it.`,
  [k.CUSTOMIZATIONS12]: `Market Place: Let your customer get all from your App.
                      Engage them with fuel, parking, parts & pieces promotions
                      using real time data informations. `,
  [k.CUSTOMIZATIONS13]: `Colision Notification: Let your customer program automatic notification in case of collision! `,
  [k.CUSTOMIZATIONS14]: `Electric Vehicle Management: Keep customers updated about
                      charging location, vehicle autonomy.`,
  [k.CUSTOMIZATIONS15]: `Everything that you think that can be explored
                      about vehicle information. Our expertize in customizations
                      let you dream with the App your customers deserve.`,
  [k.MOBILITY_SERVICES]: 'Mobility',
  [k.MOBILITY_TITLE]: 'Mobility - Opportunities for automotive OEMs',
  [k.MOBILITY_HEAD]: `Automotive industry transformation drives the OEM´s to a diversified
                    business in the offering beyond vehicle sales. Vehicle's
                    leasing at short and long terms, Car Sharing and other Vehicle
                     Collaborative Uses create new business models that drives
                     the OEMs to the era of Mobility Services.`,
  [k.MOBILITY1]: `trackIQ Cloud Based Platform - trackIQCloud, helps OEM´s to operate
                and manage Mobility Services in real time, assuring fleet full
                visibility, security and control.`,
  [k.MOBILITY2]: `Open API´s engage different Mobility Ecosystem partners as Specialized
                CRM System, Ticket Fine Systems, Car Sharing Platform, Fuel Card
                Companies, financial institution and insurance company partners,
                among others to consume and make available valuable data.
                trackIQCloud harmonize all the data in a single customized Mobility
                 Service Business Intelligence (BI) Dashboard.`,
  [k.MOBILITY3]: `Machine Learning (ML) engine helps OEMs from automotive industry,
                Dealers and other OEM's Mobility Service Partners to use valuable
                information to have actionable insights to substantially impact bottom
                 line business performance, sustainable business growth and customer satisfaction.`,
  [k.MOBILITY4]: `Building Blocks Service Dashboard in the picture.`,
  [k.MOBILITY5]: ``,
  [k.MOBILITY6]: ``,
  [k.MOBILITY7]: ``,
  [k.MOBILITY8]: ``,
  [k.MOBILITY9]: ``,
  [k.MOBILITY10]: ``,
  [k.MOBILITY11]: ``,
  [k.CONNECTED_VEHICLES]: 'Connected Vehicles',
  [k.CONNECTED_TITLE]: `Your partner for a 'Connected Solution'`,
  [k.CONNECTED_HEADER]: `The trackIQ is working hard in the process of
                        contribute for the Universal definition of 'Connected Vehicle'.
                        Technology is at the heart of the OEM Automotive Industry
                        and it grows at a pace that is not yet clear where
                        'Connected car' definition will end.
                        Automotive manufacturers are selling ‘Connected Vehicles’
                        from a simply connected to the internet cellular connection,
                        with limited feature set, to a fully connected vehicle
                        necessary in all stages of Autonomous-driving technology.
                        A real Connected Car, must be much more than a simple
                        WiFi hotspot and become part of peoples life, connected
                        all times, and sharing information as it is desired in a real
                         connected world.`,
[k.CONNECTED_CARD]: `The trackIQ is working hard in the process of
                contribute for the Universal definition of 'Connected Vehicle'.
                Technology is at the heart of the OEM Automotive Industry
                and it grows at a pace that is not yet clear where
                'Connected car' definition will end. Automotive manufacturers are selling
                ‘Connected Vehicles’ from a simply connected to the internet cellular connection,
                with limited feature set, to a fully connected vehicle necessary in all stages
                of Autonomous-driving technology.`,
  [k.WHAT1]: `For the Vehicle users:`,
  [k.WHAT2]: `   -  Connected to Internet All times.`,
  [k.WHAT3]: `   -  Smooth transition - House / Office lifestyle to 'Connected Cars'.`,
  [k.WHAT4]: `   -  User interaction with Digital world in a seamless way.`,
  [k.WHAT5]: `For the Vehicle manufacturers:`,
  [k.WHAT6]: `   -  Reliable Telematics Units for a seamless connection.`,
  [k.WHAT7]: `   -  Easy installation with 'Plug and Play' connection to Cloud Services.`,
  [k.WHAT8]: `   -  Diagnosis, BI, Data analysis, FOTA/OTA in a single software platform.`,
  [k.DESIGN]: 'Design',
  [k.APP_SYSTEM_DESIGN]: `APP / SYSTEM DESIGN`,
  [k.UNIQUE_DESIGN]: `UNIQUE DESIGN`,
  [k.FOR_ALL_DEVICES]: `For all devices`,
  [k.HEADUNITS]: 'Integration with OEM Head units - Infotainment devices.',
  [k.TABLETS]: 'Tablets for professional monitoring of your vehicles.',
  [k.SPECIAL]: 'Special devices and OS integration to support your project.',
  [k.OS]: 'Operating Systems: iOS and Android ready.',
  [k.DESIGN_TECHNOLOGY]: 'Design & Technology',
  [k.DESIGN_HEAD]: `trackIQ has in-house expertise in the development
                    of customized solutions for your 'Connected Vehicle', no matter the
                    device you have choosen: Headunits, Smartphones, tablets for
                    the top available OS vendors.`,
  [k.DESIGN1]: "Connection with In-Vehicle WiFi - Hotspot.",
  [k.DESIGN2]: "OEM's `Look and Feel` HMI customizations.",
  [k.DESIGN3]: "UX, UI, HMI customized by OEM vehicle personality.",
  [k.DESIGN4]: "Modularity, Flexibility, Scalability.",
  [k.APPS]: "APPS",
  [k.DESIGN_APP1]: "Design of Apps customization based in the following factors:",
  [k.DESIGN_APP2]: "APP Skin - OEM Brand, colors, logos, letter fonts, letter sizes.",
  [k.DESIGN_APP3]: "App Identity - Classic, Sports, Family, Service, Fleet vehicles, etc.",
  [k.DESIGN_APP4]: "App usage - B2B - Service Private fleet.",
  [k.DESIGN_APP5]: "App usage - B2C - Customers users of the vehicles and services.",
  [k.DESIGN_APP6]: "App Packages: Several packages available to satisfy OEM users",
  [k.DESIGN_APP7]: "App Features for the OEMs - Intelligence Services.",
  [k.DESIGN_APP8]: `App Features for the consumers - Hundreds of different functionalities
                    in several afforadable packages.`,
  [k.OUR_APP]: "Our App",
  [k.DESIGN_OUR_APP1]: "UX, UI Taylored according to OEMs specifications.",
  [k.DESIGN_OUR_APP2]: "Look and Feel based on vehicles category;",
  [k.DESIGN_OUR_APP3]: "On-board marketing toll for services and support.",
  [k.DESIGN_OUR_APP4]: "Operating Systems: iOS and Android ready.",
  [k.DESIGN_OUR_APP5]: "Vehicle Health available at your hands.",
  [k.DESIGN_OUR_APP6]: "Group information for safety, information and fun.",
  [k.DESIGN_OUR_APP7]: "Service support at your service.",
  [k.DESIGN_OUR_APP8]: "Breakdown, Medical, Safety Emergencies.",
  [k.ARTIFICIAL_INTELLIGENCE]: `Artificial Intelligence`,
  [k.ARTIFICIAL_INTELLIGENCE_AI_S]: `Artificial intelligence (AI) systems, which use data and
                  algorithms to mimic the cognitive functions of the human mind,
                  and have the ability to learn and solve problems
                  independently, are rapidly being deployed across a variety of
                  industries and use cases. The automotive industry is among the
                   industries at the forefront of using AI to mimic, augment,
                   and support the actions of humans, while simultaneously
                   leveraging the advanced reaction times and pinpoint
                   precision of machine-based systems. Indeed, today’s
                   semi-autonomous vehicles and the fully autonomous vehicles
                   of the future will rely heavily on AI systems.`,
  [k.AI1]: `Data & algorithms mimic the cognitive functions of the human mind.`,
  [k.AI2]: `Ability to learn and solve problems independently.`,
  [k.AI3]: `Deployed across a variety of industries and use cases.`,
  [k.AI4]: `Advanced reaction times - precision of machine-based systems`,
  [k.AI5]: `The automotive industry is using AI for:`,
  [k.AI6]: `   -  To mimic, augment, and support the actions of humans.`,
  [k.AI7]: `   -  Make driver's life in the car more convenient and safer.`,
  [k.AI8]: `   -  In-car assistants, driven by natural language processing.`,
  [k.AI9]: `   -  Systems to respond to voice commands and infer what actions to take.`,
  [k.INTELLIGENCE]: 'Intelligence Services',
  [k.INTELLIGENCE_TITLE]: `Enables vehicles and smart devices to a 'Digital Experience'`,
  [k.INTELLIGENCE_HEAD]: `'trackIQ' - 'Intelligence Services', connects
                        the existing wireless networks and future 5G that enables
                        vehicles and smart devices, installed by the Automotive
                        industry OEMs, to communicate in intelligent mode and
                        analyse real-time data to optimize vehicle experience.
                        'Intelligence Services' allows the OEMs to securely start
                        challenging projects with the support of our package of
                        Intelligence solutions. An overview of the 'trackIQ Intelligence Services' are bellow:`,
  [k.INTELLIGENCE1]: `Recall Management: Save millions of dollars in Recall advertising
                    and management by direct communication with your relevant vehicle
                    owners in case of Recalls, structuring digital reports about recall
                    evolution and customer acknowledges.`,
  [k.INTELLIGENCE2]: `Collision and theft rate per region, medical / breakdown
                    service utilization rate per region, among other intelligence report services.`,
  [k.INTELLIGENCE3]: `Business Intelligence: Customized BI development for operations and business management.`,
  [k.INTELLIGENCE4]: `Electric Vehicle Management: Keep customers updated about charging
                    location (private and and OEM partners updated about key location
                    for charging installation. Notify customer about charging remaining time.`,
  [k.INTELLIGENCE5]: `Warranty Management: Deep analysis of vehicle diagnosis information drives to
                    efficient mechanisms of informing customers, dealers and save a considerable
                    ammout of resources.`,
  [k.INTELLIGENCE6]: `Marketing Management: Helps the OEMs to manage leads, vehicle
                    conditions for negotiations of a new one, send real-time information
                    to customers based on driving behavior, region of driving, promotions on
                    important items for the vehicle, etc. Ex: Vehicle reaching oil / tires usage limit - inform
                    customers about promotions or benefits of replacing it on time in a dealer site.`,
  [k.INTELLIGENCE7]: ``,
  [k.INTELLIGENCE8]: ``,
  [k.INTELLIGENCE9]: ``,
  [k.INTELLIGENCE10]: ``,
  [k.INTELLIGENCE11]: ``,
  [k.TECHNOLOGY]: 'Technology',
  [k.MACHINE_LEARNING]: `Machine Learning`,
  [k.ML1]: `ML is helping to bring operational and business transformations`,
  [k.ML2]: `Big Data and machine learning are remodeling the automotive companies processes.`,
  [k.ML3]: `Increased level of accuracy in decision-making and improved performance.`,
  [k.ML4]: `In-vehicle intelligence Reinforcing communication among navigation systems,
          human machine interfaces, and location platforms to inform drivers about
          hazardous road situations.`,
  [k.ML5]: `Predictive vehicle maintenance Collecting data from in-vehicle units and
          applying predictive analytics to alert drivers about urgent or planned maintenance.`,
  [k.ML6]: `Smart routing and POI optimization Teaching a vehicle the most commonly used
          routes and points of interest with artificial intelligence in automotive.`,
  [k.ML7]: ``,
  [k.ML8]: ``,
  [k.ML9]: ``,
  [k.PROFESSIONAL_TITLE]: `Professional services available to your customers`,
  [k.PROFESSIONAL_HEAD]: `In the digital Era, services need to be simple, friendly
                        and automatized. New technologies bring new kind of customers,
                        who expect quick answers. Customers are more connected
                        with technology and expect to have everything in a toutch
                        of a button. trackIQ 'Professional Services' is a 24 x 7 hs
                        Support Center available to attend automotive industry
                        OEM's customer based on established procedures,with a
                        relyable Service Level Agreement (SLA) and scripts defined
                        with Automotive Customers. The 'Connected Vehicle' Service
                        should come together with Customer Care Services for
                        Support and Emergency during working hours or 24 x 7 hs.`,
[k.PROFESSIONAL_CARD]: `In the digital Era, services need to be simple, friendly
                        and automatized. New technologies bring new kind of customers,
                        who expect quick answers. Customers are more connected
                        with technology and expect to have everything in a toutch
                        of a button. trackIQ 'Professional Services' is a 24 x 7 hs
                        Support Center available to attend automotive industry
                        OEM's customer based on established procedures,with a
                        relyable Service Level Agreement (SLA) and scripts defined
                        with Automotive Customers.`,
  [k.PROFESSIONAL1]: `App Support and Training for OEM Apps and use the best of your App resources.`,
  [k.PROFESSIONAL2]: `Medical Emergencies: Support you and connect you with your parents in case of medical emergency or accidents.`,
  [k.PROFESSIONAL3]: `Breakdown Emergencies: Help your customers to get support in case of vehicle problem. The vehicle can be quickly in your dealers, not in other 3rd part repair centers. `,
  [k.PROFESSIONAL4]: `Stolen Vehicle Recovery: In countries with high theft rates, we provide 24 x 7 hs vehicle recovery service in case of robbery or stolen vehicle. Active risk assessment in boarders or theft risk areas can be an important service to reduce insurance policy price.`,
  [k.PROFESSIONAL5]: `Marketing Campaigns: Support OEM Customers with questions during marketing campaigns directed to OEM Customers Apps.`,
  [k.PROFESSIONAL6]: `Mobility Services: Support customer mobility services field emergency as a 24 x 7 hs emergency center.`,
  [k.PROFESSIONAL7]: `Concierge: Concierge for OEM customers during working hours or 24 x 7 hs.`,
  [k.PROFESSIONAL8]: ``,
  [k.PROFESSIONAL9]: ``,
  [k.CONSULTING_TITLE]: `Best way to your 'Connected Vehicle'`,
  [k.CONSULTING_HEAD]: `the 'trackIQ' team has the expertise and experience
                      to help OEMs of the automotive industry in the whole process
                      of designing a 'Connected Vehicle' and Mobility Services Strategy.
                      Design and development's support until project rollout.
                      Below some of the services we can offer to OEMs of the automotive industry:`,
  [k.CONSULTING1]: `Connected Vehicle and Mobility Service Package Definition and Customization.`,
  [k.CONSULTING2]: `Manufacturing Automotive TCU activation procedures and connectivity validation with Cloud Services.`,
  [k.CONSULTING3]: `Integration with Legacy Systems.`,
  [k.CONSULTING4]: `SIM Card data package definition and SIM management.`,
  [k.CONSULTING5]: `Business Monetization - Vehicle high speed data embedded.`,
  [k.CONSULTING6]: `Vehicle Service Activation, update and upgrade methologies.`,
  [k.CONSULTING7]: `Automotive ecosystem development & integration.`,
  [k.CONSULTING8]: `3rd Part Platform Integration.`,
  [k.CONSULTING9]: `Customer Service / Subscription engagement .`,
  [k.CONSULTING10]: `Customized Design of OEMs App.`,
  [k.CONSULTING11]: `Vehicle data harmonization and much more .............`,
  [k.ELECTRIC_HEAD]: `trackIQ 'Intelligence Services', connects the existing
                      and future 5G wireless networks that enables vehicles and
                      smart devices, installed by the OEMs, to communicate in intelligent
                      mode and analyses real-time data to optimize vehicle experience.
                      Electric, semi-autonomous and autonomous vehicles need close
                        supervision to offer a reliable and enjoyable experience to the users.
                        trackIQ is ready to play this role in this new and exciting segment.
                        An overview of the trackIQ Electric vehicles Services are bellow:`,
  [k.ELECTRIC1]: `Monitoring Hardware and software failures. Electronic systems fail due to false sensors,
               noise in signals and software errors. Electric vehicles will certainly have failures that
               could cause crashes, trackIQ can analyze data and take quick actions to avoid
               risk situations.`,
  [k.ELECTRIC2]: `Electric Vehicle Management: Keep customers updated about charging
               location (private and and OEM partners updated about key location
               for charging installation. Notify customer about charging remaining time`,
  [k.ELECTRIC3]: `Services provided by trackIQ: Battery reports, Remaining range
                Driver data analytics, DTCs notifications, Customized alerts, Mileage reports,
                Remote diagnoses, Warranty management monitoring, OTA / FOTA, Charger status,
                Charging Public / Private locations, Remote charger resets, Smart charging,
                Best route based on charger locations, etc.`,
  [k.ELECTRIC4]: `Integration with the Battery Monitoring System. BMS provides status information in real
                time to vehicle owners and send that information to the cloud to be processed
                and trigger actions to provide the best user experience to the vehicle owners.`,
  [k.ELECTRIC5]: ``,
  [k.ELECTRIC6]: ``,
  [k.ELECTRIC7]: ``,
  [k.ELECTRIC8]: ``,
  [k.ELECTRIC9]: ``,
  [k.ELECTRIC10]: ``,
  [k.ELECTRIC11]: ``,
  [k.CORPORATE]: `Corporate Fleet`,
  [k.CORPORATE_TITLE]: 'Partner of Corporate Fleet Managers',
  [k.CORPORATE_HEAD]: `Branding, image and reputation are a long journey for corporations to establish in their customers' minds.
                      in the minds of their customers. And proper care of their fleets, their
                      drivers (employees) and serving their end customers in a safe manner
                      becomes an essential part of this journey.`,
  [k.CORPORATE1]: `With this in mind and treating security as a priority,
                  trackIQ hosts its cloud platform with global providers,
                  in a secure, reliable, scalable and stable environment to run
                  automotive applications.`,
  [k.CORPORATE2]: `The environments of global providers are continually updated to make them
                  more secure in order to comply with the general regulation of data protection law on a global level.
                  at a global level. Our interfaces with other partners in the automotive ecosystem
                  ecosystem partners (APIs) and our in-vehicle products use the most modern
                  in order to guarantee the end-to-end security of information.`,
  [k.CORPORATE3]: `We carry out continuous and comprehensive penetration tests with specialized third-party
                  companies to mitigate risks and ensure the continuous evolution of the platform's security.
                  platform. Data is stored in encrypted accounts with 3 copies, and is
                  replicated in other disaster recovery backup sites located in the USA.`,
  [k.CORPORATE4]: `trackIQ supports corporations and their fleets in achieving service excellence,
                  with reports, graphs, alerts and integrations with other partners in the automotive ecosystem,
                  in order to extract consolidated information about their vehicles, their drivers and the efficiency of their operation.
                  efficiency of your operation.`,
  [k.CORPORATE5]: `In addition to security services for its mobile assets (monitoring and recovery of stolen
                  recovery), an essential service in countries with high vehicle accident rates, the following
                   functionalities or sets of functionalities are available on the platform, depending on the
                   service package and equipment included in the solutions:`,
  [k.CORPORATE6]: `Active tracking using different on-board technologies (tracker, on-board computer, video telemetry)`,
  [k.CORPORATE7]: `Driver identification by i-button or RFID with the possibility of blocking the vehicle in the event of non-identification.`,
  [k.CORPORATE8]: `Digital maps with different layers (satellite, traffic, etc.) with quick tools for registering points of interest, electronic fences, etc.
                  points of interest, electronic fences, etc.`,
  [k.CORPORATE9]: `Vehicle grid with quick access to vehicle information (battery, time of last communication,
                  vehicle status, location, etc.).`,
  [k.CORPORATE10]: `Locating the vehicle closest to a point of interest.`,
  [k.CORPORATE11]: `Reminder of scheduled servicing by km or odometer.`,
  [k.CORPORATE12]: `Real-time alerts on the platform and reports on the handling of alerts.`,
  [k.CORPORATE13]: `Alerts for the driver in the vehicle cabin covering excessive speed, braking, acceleration and sharp turns,
                  idling and lack of driver identification.`,
  [k.CORPORATE14]: `Generation of reports focusing on vehicles or drivers.`,
  [k.CORPORATE15]: `Reports with routes, routes traveled, positions (location), excessive speed, electronic fences,
                    Points of Interest, Permanence at Points of Interest.`,
  [k.CORPORATE16]: `Consolidated report with start time, end time and travel time, odometer, time on, time in motion,
                    time off, maximum speed and driver identification.`,
  [k.CORPORATE17]: `Report on driver behavior and driving errors, including violations such as acceleration,
                    sudden braking and cornering and excessive speed.`,
  [k.CORPORATE18]: ``,
  [k.CORPORATE18_1]: `    - For CAN devices, reports including CAN bus data.`,
  [k.CORPORATE18_2]: `    - In equipment with Video Telemetry, reports and alerts of driving risks and
                      and driver risk ranking including Fatigue, Distraction, Drowsiness,
                      Cigarette smoking, cell phone use, etc.`,
  [k.CORPORATE18_3]: `    - In commercial vehicles, Drivers' Working Hours report.`,
  [k.CORPORATE18_4]: `    - On buses that follow ANTT regulations, travel reports,
                      itineraries and passengers.`,
  [k.CORPORATE19]: `Mobile Asset Usage Control Reports and Dashboards (Graphs, Visuals)
                    including use during business hours, out of business hours, weekends and holidays, with
                    holidays with ranking of distance traveled by time slot.`,
  [k.CORPORATE20]: `Reports and Dashboards (Graphs, Visuals) for Fleet Efficiency Management
                    Fleet Efficiency Management, including Kilometers Traveled, Asset Use Efficiency, ranking of
                    distance traveled, delays in starting activities and premature end of activities
                    activities, ranking of fleet inactivity and ranking of vehicles with
                    little use.`,
  [k.CORPORATE21]: `Reports and Dashboards (Graphs, Visuals) for Fleet Efficiency Management
                    Fleet Efficiency Management, including Kilometers Traveled, Asset Use Efficiency, ranking of
                    distance traveled, delays in starting activities and premature end of activities
                    activities, ranking of fleet inactivity and ranking of vehicles with
                    little use.`,
  [k.CORPORATE22]: `Reports and Dashboards (Graphical, Visual) with Monthly Ranking of Infractions by vehicle, type
                    of infraction and the fleet's final score with a monthly evolutionary view.`,
  [k.CORPORATE23]: `Fuel management and supply with integration with fuel management platforms.`,
  [k.CORPORATE24]: `Solutions for monitoring electric vehicles (charging status, percentage of charge
                    of electric vehicles, energy consumption (specific projects using OBD -
                      On-board computers).`,
  [k.RENTAL_CAR]: `Rental Vehicles`,
  [k.RENTAL_TITLE]: `Partner of Rental and Car Sharing Services Companies.`,
  [k.RENTAL_HEAD]: `The trackIQ provides a wide range of services and solutions for safety, mobility
                  and fleet management for rental companies and fleet outsourcing companies.`,
  [k.RENTAL0]: `For rental companies that offer services to individual customers, whether they are individuals
                or private passenger transportation service providers, among others, our solutions cover:`,
  [k.RENTAL1]: `Monitoring, real-time location and recovery of stolen vehicles.`,
  [k.RENTAL2]: `Vehicle grid with quick access to vehicle information (battery, time of last communication,
                vehicle status, location, etc.).`,
  [k.RENTAL3]: `Digital maps with different layers (satellite, traffic, etc.) with quick registration tools
                points of interest, electronic fences, etc.`,
  [k.RENTAL4]: `Reminder of scheduled servicing by km or odometer.`,
  [k.RENTAL5]: `Jamming detection solution and vehicle towing detection.`,
  [k.RENTAL6]: `Alert system for locating vehicles outside the region scheduled for use.`,
  [k.RENTAL7]: `Solutions for measuring fuel consumption by rental.`,
  [k.RENTAL8]: `Position, odometer and speed reports.`,
  [k.RENTAL9]: ``,
  [k.RENTAL10]: `For fleet outsourcing companies, which serve different types of corporate clients, in addition
                to the features presented above, our solutions include:`,
  [k.RENTAL11]: `Driver identification by i-button or RFID with the possibility of
                 blocking the vehicle in the event of non-identification.`,
  [k.RENTAL12]: `Alerts to the driver in the event of driving outside
                the standards set by the company.`,
  [k.RENTAL13]: `Alerts for the driver in the vehicle cabin covering excessive speed, braking, acceleration and
                curves, idling and lack of driver identification.`,
  [k.RENTAL14]: `Generation of reports focusing on vehicles or drivers.`,
  [k.RENTAL15]: `Reports with routes, routes traveled, positions (location), excessive speed,
                Electronic fences, Points of Interest, Permanence at Points of Interest.`,
  [k.RENTAL16]: `Consolidated report with start time, end time and travel time, odometer, time on,
                time in motion, time off, maximum speed and driver identification.`,
  [k.RENTAL17]: `Report on driver behavior and driving errors, including infractions such as
                acceleration, sudden braking and cornering and excessive speed.`,
  [k.RENTAL18]: `Mobile Asset Usage Control Reports and Dashboards (Graphs, Visuals)
                including use during business hours, out of business hours, weekends and holidays, with
                holidays with ranking of distance traveled by time slot.`,
  [k.RENTAL19]: `Fleet Efficiency Management Reports and Dashboards (Graphs, Visuals)
                including kilometers traveled, efficiency of asset use, ranking of distance traveled,
                delay in starting activities and premature end of activities, ranking of fleet inactivity and
                ranking of vehicles with little use.`,
  [k.RENTAL20]: `Notificação de acidentes.`,
  [k.RENT1]: `Management Reports - BI`,
  [k.RENT2]: `Accident Notifications`,
  [k.RENT3]: `Operations Management`,
  [k.RENT4]: `Telemetry`,
  [k.RENT5]: `Asset Protection`,
  [k.RENT6]: `Vehicles Usage Management`,
  [k.SOLUTION1]: `Professional Services`,
  [k.SOLUTION2]: `Consulting Services`,
  [k.SOLUTION3]: `Intelligence Services`,
}
export { english };
