import i18n from 'i18next';
import k from "./../i18n/keys";
// import { useTranslation } from 'react-i18next';
import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@material-ui/core/Typography";

import { FooterContainer } from '../containers/footer';

// ============ Image Imports =============

import about from '../assets/images/about_image_2.png';

// ============ About Component ==========


export default function About() {

//   const { t } = useTranslation();
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (

        <React.Fragment>

          <Box
              sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                      display: 'block',
                      //position: 'absolute',
                      marginTop: '5.5rem',
                      width: '100%',
                      height: 80,
                  },
              }}
          >
          <Paper elevation={3} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
            <Typography className="title" variant="h4">{i18n.t(k.TRACKIQ)}</Typography>
            <Typography className="subtitle" variant="h5">{i18n.t(k.STORY_ABOUT_US)}</Typography>
          </Paper>
          </Box>
          <div className="content-container">
          <Box
              sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                      display: 'block',
                      //position: 'absolute',
                      marginTop: '0rem',
                      width: '100%',
                      height: 120,
                  },
              }}
          >
            <section id="about">
                <div className="row">
                    <div className="about" >
                        <div className="topic-header-about">
                            {/* <br /><br /><br />
                            <p className="about-subheading">{i18n.t(k.STORY_ABOUT_US)}</p> */}
                            <p className="text-center lorem1">
                            <span>
                                {i18n.t(k.ABOUT_PART_1_0)}
                            </span>
                            <span className="text-center lorem1_0">
                                {i18n.t(k.TRACKIQ)}
                            </span>
                            <span className="text-center lorem1">
                                {i18n.t(k.ABOUT_PART_1)}
                            </span>
                            </p>
                             <br />
                            <img className="img-fluid " src={about} alt="About us" />
                            <p className="about-subheading">{i18n.t(k.WHAT_WE_DO)}</p>
                        </div>

                        <div className="row about-text">
                             <p className="lorem">
                                {i18n.t(k.ABOUT_PART_2_00)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_2_0)}
                            <span className="lorem1_0">
                                {i18n.t(k.TRACKIQ)}
                            </span>
                            <span className="lorem">
                                {i18n.t(k.ABOUT_PART_2)}
                            </span>
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_3)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_4_0)}
                              <span className="lorem1_0">
                                  {i18n.t(k.TRACKIQ)}
                              </span>
                              <span className="lorem">
                                  {i18n.t(k.ABOUT_PART_4)}
                              </span>
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_5)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_6)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_7)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_8)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.ABOUT_PART_9)}
                            </p>
                            <hr />
                            <p className="about-subheading">
                                {i18n.t(k.COMPANY_VALUES_HEAD)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.COMPANY_VALUES1)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.COMPANY_VALUES2)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.COMPANY_VALUES3)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.COMPANY_VALUES4)}
                            </p>
                            <p className="lorem">
                                {i18n.t(k.COMPANY_VALUES5)}
                            </p>
                        </div>

                    </div>
                </div>
                <hr />
                <FooterContainer className="footer--pin" />
            </section>
        </Box>
        </div>

        </React.Fragment>
    );
}